import {APP_BASE_HREF, LOCATION_INITIALIZED} from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { ConsoleLoggerService } from 'src/util/console-logger.service';
import { Logger } from 'src/util/logger';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConstants } from './AppConstants';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfigureTotpComponent } from './configure-totp/configure-totp.component';
import { DukeAlertComponent } from './duke-alert/duke-alert.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormInputFeedbackComponent } from './form-input-feedback/form-input-feedback.component';
import { FormInputComponent } from './form-input/form-input.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ValidateEmailComponent } from './validate-email/validate-email.component';
import { ViewProfileComponent } from './view-profile/view-profile.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoggerErrorHandler } from './LoggerErrorHandler';
import { CanDeactivateGuardService } from './can-deactivate-guard.service';
import { LocaleService } from './locale.service';
import { AlertHandler, NoConcurrentAlertsHandler } from './no-concurrent-alerts-handler';
import { LogoComponent } from './logo/logo.component';
import {ClickOutsideDirective} from './click-outside.directive';
import { LogoutComponent } from './logout/logout.component';
import { JoinComponent } from './join/join.component';
import {getAppBaseHref, HttpLoaderFactory, getLocale} from '../util/locationUtil';
import { BsSampleComponent } from './bs-sample/bs-sample.component';
import {HomeComponent} from './home/home.component';
import {AppStateService} from './app-state.service';
import {AuthenticationGuardService} from './authentication-guard.service';
import {AuthenticateDeviceComponent} from './authenticate-device/authenticate-device.component';
import {CustomReuseRouteStrategy} from '../custom-reuse-route-strategy';
import { Configuration } from 'src/gen';
import {ConfigurationGuardService} from './configuration-guard.service';
import {ConfigurationModel} from './configuration-service';

export const appRoutes: Routes = [
  {
    path: AppConstants.ROUTE_REGISTER,
    component: RegisterComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      animation: 'Register',
      validateSession: AuthenticationGuardService.hasNoSession,
      showAfterValidateSession: false,
    },
  },
  {
    path: AppConstants.ROUTE_VALIDATE_EMAIL,
    component: ValidateEmailComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      animation: 'ValidateEmail',
      validateSession: AuthenticationGuardService.hasPartialSession,
    },
  },
  {
    path: AppConstants.ROUTE_FORGOT_PASSWORD,
    component: ForgotPasswordComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      animation: 'ForgotPassword',
      validateSession: AuthenticationGuardService.hasNoSession,
      showAfterValidateSession: false,
    },
  },
  {
    path: AppConstants.ROUTE_RESET_PASSWORD,
    component: LoginComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      animation: 'ResetPassword',
      validateSession: AuthenticationGuardService.hasNoSession,
      showAfterValidateSession: false,
    },
  },
  {
    path: AppConstants.ROUTE_LOGIN,
    component: LoginComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuardService],
    data: {
      animation: 'Login',
      validateSession: AuthenticationGuardService.hasNoSession,
      showAfterValidateSession: false,
    },
  },
  {
    path: AppConstants.ROUTE_LOGOUT,
    component: LogoutComponent,
    data: { animation: 'Logout' },
  },
  {
    path: AppConstants.ROUTE_PROFILE,
    component: ViewProfileComponent,
    canActivate: [AuthenticationGuardService],
    data: { animation: 'ViewProfile' },
  },
  {
    path: AppConstants.ROUTE_DEVICE_AUTH,
    component: AuthenticateDeviceComponent,
    canActivate: [AuthenticationGuardService],
    data: { animation: 'AuthenticateDevice' },
  },
  {
    path: AppConstants.ROUTE_EDIT_PROFILE,
    component: EditProfileComponent,
    canActivate: [AuthenticationGuardService],
    canDeactivate: [CanDeactivateGuardService],
    data: { animation: 'EditProfile' },
  },
  {
    path: AppConstants.ROUTE_CHANGE_PASSWORD,
    component: ChangePasswordComponent,
    canActivate: [ConfigurationGuardService, AuthenticationGuardService],
    data: {
      animation: 'ChangePassword',
      blockAccess: (props: ConfigurationModel) => {
        return !props.profileLoginFields?.find((f) => f.key === 'password' && f.readonly !== true);
      }
    },
  },
  {
    path: AppConstants.ROUTE_CONFIGURE_TOTP,
    component: ConfigureTotpComponent,
    canActivate: [ConfigurationGuardService, AuthenticationGuardService],
    data: {
      animation: 'ConfigureTotp',
      blockAccess: (props: ConfigurationModel) => {
        return !props.profileLoginFields?.find((f) => f.key === 'totp' && f.readonly !== true);
      }
    },
  },
  {
    path: AppConstants.ROUTE_JOIN,
    component: JoinComponent,
    data: {
      // Animation value is relevant only when it changes, so to allow animation from join to join, we must include something that changes.
      // anything after a ":" will be replaced by current value of a matching param or queryParam when resolving the animation to use.
      // multiple values can be separated with a ","
      animation: 'Join:' + AppConstants.QP_INVITATION_ID,
      // bypasses the angular stupidity of not triggering navigation events, if consequent routes happen to use the same primary component.
      neverIgnoreNavigation: true,
    },
  },
  {
    path: AppConstants.ROUTE_HOME,
    component: HomeComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      animation: 'Home',
      validateSession: AuthenticationGuardService.never,
      showAfterValidateSession: false,
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      animation: 'NotFound',
      validateSession: AuthenticationGuardService.always,
    },
  },
];
if (environment.production !== true) {
  appRoutes.unshift({
    path: AppConstants.ROUTE_BS_SAMPLE,
    component: BsSampleComponent,
    data: { animation: 'BS_SAMPLE' },
  });
}

export function appInitializerFactory(localeService: LocaleService, translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    // not sure what this is, docs say it "Indicates when a location is initialized."
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang('en');
      const locale = getLocale();
      // check if any locale was requested
      if (locale) {
        localeService.setLocaleParam(locale);
        // try using requested locale
        translate.use(locale).subscribe(res => {
          // success, all done.
          localeService.setIsAvailable(true);
          resolve(null);
        }, e => {
          // requested locale not supported, check if language is available without the country part, if country part is found
          if (locale.indexOf('-') > 0) {
            translate.use(locale.split('-')[0]).subscribe(res => {
              // success, all done.
              localeService.setIsAvailable(true);
              resolve(null);
            }, err => {
              // failed, locale not supported. Leave in path for future support and show notification
              localeService.setIsAvailable(false);
              resolve(null);
            });
          } else {
            // failed, locale not supported. Leave in path for future support and show notification
            localeService.setIsAvailable(false);
            resolve(null);
          }
        });
      } else {
        // no locale was requested, continue with default.
        localeService.setLocaleParam(null);
        localeService.setIsAvailable(true);
        resolve(null);
      }
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ValidateEmailComponent,
    ForgotPasswordComponent,
    EditProfileComponent,
    ViewProfileComponent,
    DukeAlertComponent,
    FormInputComponent,
    FormInputFeedbackComponent,
    ChangePasswordComponent,
    ConfigureTotpComponent,
    PageNotFoundComponent,
    LogoComponent,
    ClickOutsideDirective,
    LogoutComponent,
    JoinComponent,
    HomeComponent,
    BsSampleComponent,
    AuthenticateDeviceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    environment.sessionApi,
    NgbModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false,
    onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }
    ),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: Logger, useClass: ConsoleLoggerService },
    { provide: AlertHandler, useClass: NoConcurrentAlertsHandler },
    AppStateService,
    AuthenticationGuardService,
    ConfigurationGuardService,
    CanDeactivateGuardService,
    { provide: ErrorHandler, useClass: LoggerErrorHandler },
    { provide: APP_BASE_HREF, useFactory: getAppBaseHref },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [LocaleService, TranslateService, Injector],
      multi: true
    },
    {provide: RouteReuseStrategy, useClass: CustomReuseRouteStrategy},
    { provide: Configuration, useFactory: environment.confGetter },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

