<div
  class="card standalone fade change-password-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1
      class="mb-0"
    >
      {{'change-password.title'|translate}}
    </h1>
  </div>
  <form
    [formGroup]="changePasswordForm"
    (ngSubmit)="submit()"
  >
    <div class="card-body">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input
        type="text"
        name="userName"
        autocomplete="username"
        hidden
        [value]="getProfile() ? getProfile().email : ''"
      />
      <div
        app-form-input
        field="currentPassword"
        autocomplete="current-password"
        label="{{'change-password.current-password.label'|translate}}"
        type="password"
        [isInvalid]="isFieldInvalid(changePasswordForm.get('currentPassword'))"
        [isValid]="isFieldValid(changePasswordForm.get('currentPassword'))"
      >
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="!hasError(changePasswordForm.get('currentPassword'))"
        >{{'change-password.current-password.error-message.required'|translate}}</div>
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="hasError(changePasswordForm.get('currentPassword'))"
        >{{'change-password.current-password.error-message.incorrect'|translate}}</div>
      </div>

      <div
        app-form-input
        field="newPassword"
        label="{{'change-password.new-password.label'|translate}}"
        type="password"
        autocomplete="new-password"
        [isInvalid]="isFieldInvalid(changePasswordForm.get('newPassword'))"
        [isValid]="isFieldValid(changePasswordForm.get('newPassword'))"
      >
        <div
          app-form-input-feedback
          [type]="!isFieldInvalid(changePasswordForm.get('newPassword')) ? 'info' : 'invalid'"
        ><ng-container *ngIf="!isFieldInvalid(changePasswordForm.get('newPassword'))">{{'change-password.new-password.info'|translate}}</ng-container>
          <ng-container *ngIf="isFieldInvalid(changePasswordForm.get('newPassword'))">{{'change-password.new-password.error-message.required-or-invalid'|translate}}</ng-container>
        </div>
      </div>

      <div
        app-form-input
        field="confirmNewPassword"
        label="{{'change-password.confirm-password.label'|translate}}"
        type="password"
        autocomplete="new-password"
        [isInvalid]="isFieldInvalid(changePasswordForm.get('confirmNewPassword'))"
        [isValid]="isFieldValid(changePasswordForm.get('confirmNewPassword'))"
      >
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="isFieldInvalid(changePasswordForm.get('confirmNewPassword'))"
        >{{'change-password.confirm-password.error-message.required-or-invalid'|translate}}</div>
      </div>
      <hr *ngIf="hasError()" />
      <app-duke-alert
        data-test-submit-error-message
        type="danger"
        *ngIf="hasError()"
        alertClass="mb-0"
        dismissible="true"
        (open)="alertHandler.onOpen($event);"
        (close)="alertHandler.onClose($event); submitFailed = false;"
      >
        <p class="mb-0">
          {{'change-password.submit-error.message'|translate}}
        </p>
      </app-duke-alert>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <button
          data-test-submit-btn
          class="btn btn-success"
          type="submit"
          [disabled]="!changePasswordForm.valid"
        >
          {{'change-password.change-button.label'|translate}}
        </button>
        <span
          class="text-danger order-2 mr-2 text-right"
          *ngIf="hasError()"
          [@cardFooterFeedbackTransition]
        >
            <span class="icon fa fa-fw fa-exclamation-triangle"></span>
            <span>
              {{'change-password.submit-error.notification'|translate}}
            </span>
        </span>
        <a
          data-test-cancel-btn
          [routerLink]="getAppConstant('PATH_EDIT_PROFILE')"
          fragment="login"
          queryParamsHandling="merge"
          class="btn btn-secondary order-3 mr-auto"
        >
          {{'change-password.cancel-button.label'|translate}}
        </a>
      </div>
    </div>
  </form>
</div>
