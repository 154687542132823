<div
  class="card standalone validate-email-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      {{'validate-email.title'|translate}}
    </h1>
  </div>
  <form
    [formGroup]="sendEmailForm"
    (ngSubmit)="sendEmail()"
    *ngIf="showSendEmailForm()"
  >
    <div class="card-body pb-0">
      <app-duke-alert
        type="info"
        alertClass="mb-0"
      >
        <p *ngIf="showCodeInput">
          {{'validate-email.info.key-sent-copy'|translate}}
        </p>
        <p *ngIf="!showCodeInput">
          {{'validate-email.info.key-sent-copy-without-code'|translate}}
        </p>
        <p>
          {{'validate-email.info.resend-key-copy'|translate}}
        </p>
        <div
          class="form-group"
          *ngIf="email"
        >
          <div class="">
            <div class="input-group">
              <!--suppress HtmlFormInputWithoutLabel -->
              <input
                id="email"
                name="email"
                readonly
                type="text"
                formControlName="email"
                class="form-control"
              />
              <div class="input-group-append">
                <button
                  data-test-send-email-validation
                  class="btn btn-info"
                  type="submit"
                  [disabled]="sendEmailTimeout > 0"
                >{{'validate-email.info.resend-key-button.label'|translate}}
                </button>
              </div>
            </div>
            <small class="float-right text-muted" style="clear:right;" *ngIf="sendEmailTimeout > 0">
              <span class="icon fa fa-fw fa-ban mr-1"></span>
              <span [innerHTML] = "'validate-email.info.resend-wait-copy' | translate: { timeout: sendEmailTimeout }"></span>
            </small>
          </div>
        </div>
        <div
          *ngIf="!email"
          class="clearfix"
        >
          <button
            data-test-send-email-validation
            class="btn btn-info float-right"
            type="submit"
            [disabled]="sendEmailTimeout > 0"
          >{{'validate-email.info.resend-key-button.label'|translate}}
          </button>
          <small class="float-right text-muted" style="clear:right;" *ngIf="sendEmailTimeout > 0">
            <span class="icon fa fa-fw fa-ban mr-1"></span>
            <span [innerHTML] = "'validate-email.info.resend-wait-copy' | translate: { timeout: sendEmailTimeout }"></span>
          </small>
        </div>
      </app-duke-alert>
      <app-duke-alert
        type="success"
        *ngIf="emailsent"
        dismissible="true"
        data-test-email-validation-sent
        (open)="alertHandler.onOpen($event);"
        (close)="alertHandler.onClose($event); emailsent = false;"
      >
        <p class="mb-0">
          {{'validate-email.info.key-sent-notification'|translate}}
        </p>
      </app-duke-alert>
      <app-duke-alert
        type="danger"
        *ngIf="emailfailed"
        data-test-email-validation-send-failed
        dismissible="true"
        (open)="alertHandler.onOpen($event);"
        (close)="alertHandler.onClose($event); emailfailed = false;"
      >
        <p class="mb-0">
          {{'validate-email.info.key-send-failed-notification'|translate}}
        </p>
      </app-duke-alert>
      <hr *ngIf="showCodeInput" />
    </div>
  </form>
  <form
    [formGroup]="validationForm"
    (ngSubmit)="submit()"
  >
    <div
      class="card-body"
      [ngClass]="{'pt-0': showSendEmailForm()}"
    >
      <input *ngIf="!showCodeInput"
             type="hidden"
             name="key"
             id="key"
             formControlName="key"
      />
      <div
        *ngIf="showCodeInput"
        app-form-input
        field="key"
        size="lg"
        label="{{'validate-email.key.label'|translate}}"
        [isInvalid]="isFieldInvalid(validationForm.get('key'))"
        [isValid]="isFieldValid(validationForm.get('key'))"
      >
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="!validationCodeExpired && isFieldInvalid(validationForm.get('key'))"
        >{{'validate-email.key.error-message.required-or-invalid'|translate}}</div>
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="validationCodeExpired && isFieldInvalid(validationForm.get('key'))"
        >{{'validate-email.key.error-message.expired'|translate}}</div>
      </div>
      <app-duke-alert
        type="danger"
        data-test-submit-failed-message
        *ngIf="hasError()"
        alertClass="mb-0"
        dismissible="true"
        (open)="alertHandler.onOpen($event);"
        (close)="alertHandler.onClose($event); genericError = false;"
      >
        <p class="mb-0" *ngIf="!tooManyAttemptsError">
          <ng-container *ngIf="showCodeInput">
            {{'validate-email.submit-error.message.failed'|translate}}
          </ng-container>
          <ng-container *ngIf="!showCodeInput">
            {{'validate-email.submit-error.message.failed'|translate}}:
            <ng-container *ngIf="!validationCodeExpired && isFieldInvalid(validationForm.get('key'))">
              {{'validate-email.submit-error.message.part-invalid-link'|translate}}
            </ng-container>
            <ng-container *ngIf="validationCodeExpired && isFieldInvalid(validationForm.get('key'))">
              {{'validate-email.submit-error.message.part-expired-link'|translate}}
            </ng-container>
          </ng-container>
        </p>
        <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'validate-email.submit-error.message.tooManyAttempts' | translate"></p>
        <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'validate-email.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
        <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'validate-email.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>

      </app-duke-alert>
    </div>
    <div class="card-footer" *ngIf="showCodeInput">
      <div class="d-flex align-items-center flex-row-reverse">
        <span
          class="text-danger order-2 mr-2 text-right"
          *ngIf="hasError()"
          [@cardFooterFeedbackTransition]
        >
          <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
            <span class="icon fa fa-fw fa-exclamation-triangle"></span>
            <span>
              {{'validate-email.submit-error.notification.failed'|translate}}
            </span>
          </ng-container>
          <ng-container *ngIf="hasTooManyAttemptsTimeout()">
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span [innerHTML] = "'validate-email.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }">
              </span>
          </ng-container>
        </span>
        <span
          class="text-muted order-2 mr-2 text-right"
          *ngIf="!hasError() && hasTooManyAttemptsTimeout()"
          [@cardFooterFeedbackTransition]
        >
            <span class="icon fa fa-fw fa-ban mr-2"></span>
            <span [innerHTML] = "'validate-email.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }">
            </span>
        </span>
        <button
          data-test-validate-email
          class="btn btn-success"
          type="submit"
          [disabled]="!validationForm.valid || hasTooManyAttemptsTimeout()"
        >
          {{'validate-email.validate-button.label'|translate}}
        </button>
        <button type="button" class="btn btn-secondary order-3 mr-auto"
           (click)="cancel()"
        >{{'validate-email.cancel-button.label'|translate}}</button>
      </div>
    </div>
  </form>
</div>
