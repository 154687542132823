<ng-template #secondaryFlows>
  <ng-container *ngIf="showSecondaryFlows()">
    <hr />
    <div class="clearfix">
      <h2 class="h6 float-left mt-2">{{'login.alternative-flow-title'|translate}}</h2>
      <ul class="list-unstyled float-right mb-0">
        <ng-container *ngFor="let flow of getAvailableFlows()">
          <li *ngIf="isValidSecondaryFlow(flow)" class="float-right ml-2 mb-2">
            <button
              type="button"
              [ngClass]="getFlowButtonClass(flow)"
              (click)="chooseFlow(flow);"
              [innerHTML]="getFlowButtonLabel(flow)"
            >
            </button>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</ng-template>
<div
  class="card standalone login-component"
  [attr.data-test-is-reset-password]="isResetPassword()"
  [ngClass]="{
    'show': isReady()
  }"
>
  <app-duke-alert
    type="danger"
    *ngIf="showServerError"
    alertClass="mb-0"
    dismissible="true"
    (close)="setShowServerError(false)"
  >
    <p
      class="mb-0"
      [innerHTML]="'login.server-error'|translate"
    ></p>
  </app-duke-alert>
  <ng-container *ngIf="!processingDelegated">
    <div class="card-header text-center" *ngIf="isResetPassword() && !isActiveFlowStep('password')">
      <h1 class="mb-0">
        {{'login.title.reset-password'|translate}}
      </h1>
    </div>
    <div class="card-header text-center" *ngIf="isResetPassword() && isActiveFlowStep('password') && !showCodeInput && !resetPasswordForm.get('code').valid">
      <h1 class="mb-0">
        {{'extras.reset-password-secondary-title'|translate}}
      </h1>
    </div>
    <div class="card-header text-center" *ngIf="isResetPassword() && isActiveFlowStep('password') && !showCodeInput && resetPasswordForm.get('code').valid">
      <h1 class="mb-0">
        {{'extras.reset-password-endstep-title'|translate}}
      </h1>
    </div>
    <div class="card-header text-center dropright" *ngIf="!isResetPassword()"
         #cardHeader>
      <h1>
        {{'login.title.default' | translate}}
      </h1>
      <p *ngIf="isActiveFlowStep()" class="mb-0">
        {{'login.subtitle.default' | translate: { service_name: configuration.getProperties().serviceName } }}
      </p>
      <p *ngIf="!isActiveFlowStep()" class="mb-0">
        {{'login.subtitle.username' | translate}}
      </p>
      <button
        type="button"
        id="usernameHolder"
        class="btn btn-auto btn-lg btn-block dropdown-toggle mb-0 text-truncate{{!isActiveFlowStep() ? ' show' : ''}}"
        [ngbTooltip]="('login.profile-card.tooltip' | translate)"
        (click)="first(getCurrentFlowStepTab())"
        #restartBtn
      >
        <span class="icon fa fa-fw fa-user-circle align-middle"></span> {{defaultForm.get('userName').value}}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="processingDelegated">
    <div class="card-header text-center" *ngIf="!isResetPassword()">
      <h1>
        {{'login.title.default' | translate}}
      </h1>
      <p class="mb-0">
        {{'login.processing-delegated-info'|translate}}
      </p>
    </div>
  </ng-container>
  <div class="tab-content" #tabHolder *ngIf="!processingDelegated">
    <div
      #usernameTab
      id="usernameTab"
      class="tab-pane"
      [ngClass]="{
        'active': isActiveFlowStep(),
        'show': isActiveFlowStep()
      }"
    >
      <form
        [formGroup]="defaultForm"
        (ngSubmit)="next()"
      >
        <div class="card-body">
          <div
            app-form-input
            #userName
            field="userName"
            type="text"
            size="lg"
            label="{{'login.user-name.label' | translate}}"
            autocomplete="username email"
            [isInvalid]="isFieldInvalid(defaultForm.get('userName'))"
            [isValid]="isFieldValid(defaultForm.get('userName'))"
            [readonly]="userNameAsFixed"
            container="body"
            placement="auto"
            [ngbTooltip]="userNameAsFixed ? ('login.user-name.fixed-tip' | translate) : undefined"
          >
            <div
              app-form-input-feedback
              type="invalid"
              *ngIf="isFieldInvalid(defaultForm.get('userName'))"
            >{{'login.user-name.error-message.required-or-invalid' | translate}}</div>
          </div>
          <div class="form-group clearfix" *ngIf="showRememberMe">
            <div class="custom-control custom-checkbox float-right">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                formControlName="rememberMe"
                class="custom-control-input"
              />
              <label
                class="custom-control-label"
                for="rememberMe"
              >
                {{'login.remember-me.label' | translate}}
              </label>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="secondaryFlows"></ng-container>
        </div>
        <div class="card-footer">
          <div class="d-flex align-items-center flex-row-reverse">
            <span
              class="text-danger order-2 mr-2 text-right"
              *ngIf="showSignInFailed()"
              [@cardFooterFeedbackTransition]
              data-test-submit-failed-notification
            >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>
                  {{'login.submit-error.notification.failed' | translate}}
                </span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span [innerHTML] = "'login.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }">
                </span>
              </ng-container>
            </span>
            <span
              class="text-muted order-2 mr-2 text-right"
              *ngIf="!showSignInFailed() && hasTooManyAttemptsTimeout()"
            >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span [innerHTML] = "'login.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>
            <button
              type="submit"
              class="order-1 btn btn-primary"
              data-test-next-button
              [disabled]="!defaultForm.valid"
            >
              {{'login.next-button.label' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div
      #passwordTab
      id="passwordTab"
      class="tab-pane"
      [ngClass]="{
        'active': isActiveFlowStep(passwordStepKey),
        'show': isActiveFlowStep(passwordStepKey)
      }"
    >
      <form
        [formGroup]="isResetPassword() ? resetPasswordForm : passwordForm"
        (ngSubmit)="next()"
      >
        <div class="card-body">
          <div *ngIf="isResetPassword()">

            <app-duke-alert
              type="success"
              *ngIf="isActiveFlowStep('password') && isResetPassword() && emailsent"
              dismissible="true"
              (open)="alertHandler.onOpen($event);"
              (close)="alertHandler.onClose($event); emailsent = false;"
            >
              <p class="mb-0">
                {{'login.reset-password-info.key-sent-notification' | translate}}
              </p>
            </app-duke-alert>
            <app-duke-alert
              type="danger"
              *ngIf="isActiveFlowStep('password') && isResetPassword() && emailfailed"
              dismissible="true"
              (open)="alertHandler.onOpen($event);"
              (close)="alertHandler.onClose($event); emailfailed = false;"
            >
              <p class="mb-0">
                {{'login.reset-password-info.key-send-failed-notification' | translate}}
              </p>
            </app-duke-alert>
            <app-duke-alert
              type="info"
              alertClass="mb-0"

            >
              <p *ngIf="!showCodeInput && resetPasswordForm.get('code').valid">
                {{'login.reset-password.login-info'|translate}}
              </p>
              <p *ngIf="!showCodeInput && !resetPasswordForm.get('code').valid">
                {{'login.reset-password-info.key-sent-copy-without-key' | translate}}
              </p>
              <p *ngIf="showCodeInput">
                {{'login.reset-password-info.key-sent-copy' | translate}}
              </p>
              <p *ngIf="showCodeInput || !resetPasswordForm.get('code').valid">
                {{'login.reset-password-info.resend-key-copy' | translate}}
              </p>
              <form (ngSubmit)="sendEmail()" *ngIf="showCodeInput || invalidCodeError || !resetPasswordForm.get('code').valid">
                <div class="clearfix">
                  <button
                    data-test-resend-reset-password-link
                    class="float-right btn-hyperlink"
                    [disabled]="sendEmailTimeout > 0"
                    type="submit"
                  >{{'login.reset-password-info.resend-key-button.label' | translate}}
                  </button>
                  <small class="float-right text-muted" style="clear:right;" *ngIf="sendEmailTimeout > 0">
                    <span class="icon fa fa-fw fa-ban mr-1"></span>
                    <span [innerHTML] = "'login.reset-password-info.resend-wait-copy' | translate: { timeout: sendEmailTimeout }"></span>
                  </small>
                </div>
              </form>
            </app-duke-alert>
            <hr  *ngIf="showCodeInput || showPasswordInputsWithoutCode || resetPasswordForm.get('code').valid"/>
            <input *ngIf="!showCodeInput"
                   type="hidden"
                   name="code"
                   id="code"
                   formControlName="code"
            />
            <div
              *ngIf="showCodeInput"
              app-form-input
              #codeFieldForResetPassword
              field="code"
              label="{{'login.code.label' | translate}}"
              [isInvalid]="isFieldInvalid(resetPasswordForm.get('code'))"
              [isValid]="isFieldValid(resetPasswordForm.get('code'))"
            >
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="isFieldInvalid(resetPasswordForm.get('code')) && !resetPasswordForm.get('code').value"
              >{{'login.code.error-message.required' | translate}}</div>
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="hasError(resetPasswordForm.get('code')) && resetPasswordForm.get('code').value"
              >{{'login.code.error-message.invalid' | translate}}</div>
            </div>
            <ng-container *ngIf="showPasswordInputsWithoutCode || resetPasswordForm.get('code').valid">
              <div
                app-form-input
                #passwordForResetPassword
                field="passwordForResetPassword"
                type="password"
                label="{{'login.password-for-reset-password.label' | translate}}"
                autocomplete="new-password"
                [isInvalid]="isFieldInvalid(resetPasswordForm.get('passwordForResetPassword'))"
                [isValid]="isFieldValid(resetPasswordForm.get('passwordForResetPassword'))"
              >
                <div
                  app-form-input-feedback
                  [type]="!isFieldInvalid(resetPasswordForm.get('passwordForResetPassword')) ? 'info' : 'invalid'"

                ><ng-container *ngIf="!isFieldInvalid(resetPasswordForm.get('passwordForResetPassword'))">{{'login.password-for-reset-password.info' | translate}}</ng-container>
                  <ng-container *ngIf="isFieldInvalid(resetPasswordForm.get('passwordForResetPassword'))">{{'login.password-for-reset-password.error-message.required-or-invalid' | translate}}</ng-container>
                </div>
              </div>
              <div
                app-form-input
                field="confirmPassword"
                type="password"
                label="{{'login.confirm-password.label' | translate}}"
                autocomplete="new-password"
                [isInvalid]="isFieldInvalid(resetPasswordForm.get('confirmPassword'))"
                [isValid]="isFieldValid(resetPasswordForm.get('confirmPassword'))"
              >
                <div
                  app-form-input-feedback
                  type="invalid"
                  *ngIf="!hasError(resetPasswordForm.get('confirmPassword'))"
                >{{'login.confirm-password.error-message.required-or-invalid' | translate}}</div>
              </div>
            </ng-container>
            <app-duke-alert
              data-test-submit-failed-message
              type="danger"
              alertClass="mb-0"
              *ngIf="isResetPassword() && isActiveFlowStep(passwordStepKey) && hasError()"
              dismissible="true"
              (open)="alertHandler.onOpen($event);"
              (close)="alertHandler.onClose($event); genericError = undefined;"
            >
              <p class="mb-0" *ngIf="!tooManyAttemptsError && !invalidCodeError">
                {{'login.submit-error.message.failed' | translate}}
              </p>
              <p class="mb-0" *ngIf="!tooManyAttemptsError && invalidCodeError">
                {{'login.submit-error.message.reset_password_not_authorized' | translate}}
              </p>
              <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttempts' | translate"></p>
              <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError  && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
              <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>
            </app-duke-alert>
          </div>
          <div *ngIf="!isResetPassword()">
            <!--suppress HtmlFormInputWithoutLabel -->
            <input
              type="text"
              hidden
              name="userName"
              [value]="defaultForm.get('userName').value"
              autocomplete="username"
            />
            <div
              app-form-input
              #password
              field="password"
              type="password"
              label="{{'login.password.label' | translate}}"
              size="lg"
              autocomplete="current-password"
              [isInvalid]="isFieldInvalid(passwordForm.get('password'))"
              [isValid]="isFieldValid(passwordForm.get('password'))"
            >
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="!hasError(passwordForm.get('password'))"
              >{{'login.password.error-message.required' | translate}}</div>
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="hasError(passwordForm.get('password'))"
              >{{'login.password.error-message.invalid' | translate}}</div>
            </div>
            <app-duke-alert
              data-test-submit-failed-message
              type="danger"
              alertClass="mb-0"
              *ngIf="!isResetPassword() && isActiveFlowStep(passwordStepKey) && hasError()"
              dismissible="true"
              (open)="alertHandler.onOpen($event);"
              (close)="alertHandler.onClose($event); genericError = undefined;"
            >
              <p class="mb-0" *ngIf="!tooManyAttemptsError">
                {{'login.submit-error.message.failed' | translate}}
              </p>
              <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttempts' | translate"></p>
              <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
              <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>
            </app-duke-alert>
            <ng-container [ngTemplateOutlet]="secondaryFlows"></ng-container>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex align-items-center flex-row-reverse">
            <span
              class="text-danger order-2 mr-2 text-right"
              *ngIf="showSignInFailed()"
              data-test-submit-failed-notification
            >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>
                  {{'login.submit-error.notification.failed' | translate}}
                </span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span [innerHTML] = "'login.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }">
                </span>
              </ng-container>
            </span>
            <span
              class="text-muted order-2 mr-2 text-right"
              *ngIf="!showSignInFailed() && hasTooManyAttemptsTimeout()"
            >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span  [innerHTML] = "'login.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>
            <button
              *ngIf="!isLastStep() && (!isResetPassword() || (isResetPassword() && (showCodeInput || resetPasswordForm.get('code').valid)))"
              data-test-next-button
              type="submit"
              class="btn btn-primary"
              [disabled]="isResetPassword() ? !resetPasswordForm.valid : !passwordForm.valid"
            >
              {{'login.next-button.label' | translate}}
            </button>
            <button
              *ngIf="isLastStep() && (!isResetPassword() || (isResetPassword() && (showCodeInput || resetPasswordForm.get('code').valid)))"
              data-test-next-button
              type="submit"
              class="btn btn-success"
              [disabled]="(isResetPassword() ? !resetPasswordForm.valid : !passwordForm.valid) || hasTooManyAttemptsTimeout()"
            >
              {{'login.login-button.label' | translate}}
            </button>
            <button
              type="button"
              data-test-cancel-reset-password
              *ngIf="isResetPassword()"
              [class]="(showCodeInput || resetPasswordForm.get('code').valid) ? 'btn btn-secondary order-3 mr-auto' : 'btn btn-primary'"
               (click)="cancelResetPassword()"
            >{{(showCodeInput || resetPasswordForm.get('code').valid) ? ('login.reset-password-cancel' | translate) : ('login.reset-password-continue' | translate)}}</button>
            <a
              data-test-forgot-password-link
              [routerLink]="[getAppConstant('PATH_FORGOT_PASSWORD')]"
              [queryParams]="wrapAsObject(
                [getAppConstant('QP_EMAIL'), this.defaultForm.get('userName').value]
              )"
              queryParamsHandling="merge"
              class="btn btn-link order-3 mr-auto"
              *ngIf="!isResetPassword()"
            >
              <span>{{'login.forgot-password-link.label' | translate}}</span>
            </a>
          </div>
        </div>
      </form>
    </div>
    <div
      #totpTab
      id="totpTab"
      class="tab-pane"
      [ngClass]="{
        'show active': isActiveFlowStep(totpStepKey)
      }"
    >
      <form
        [formGroup]="totpForm"
        (ngSubmit)="next()"
      >
        <div class="card-body">
          <div
            app-form-input
            #totp
            field="totp"
            size="lg"
            label="{{'login.totp.label' | translate}}"
            autocomplete="one-time-code"
            [isInvalid]="isFieldInvalid(totpForm.get('totp'))"
            [isValid]="isFieldValid(totpForm.get('totp'))"
          >
            <div
              app-form-input-feedback
              type="invalid"
              *ngIf="hasError(totpForm.get('totp'))"
            >{{'login.totp.error-message.incorrect' | translate}}</div>
            <div
              app-form-input-feedback
              [type]="!isFieldInvalid(totpForm.get('totp')) ? 'info' : 'invalid'"
              *ngIf="!hasError(totpForm.get('totp'))"
            ><ng-container *ngIf="isFieldInvalid(totpForm.get('totp'))">{{'login.totp.error-message.required-or-invalid' | translate}}</ng-container>
              <ng-container *ngIf="!isFieldInvalid(totpForm.get('totp'))">{{'login.totp.help-text' | translate}}</ng-container>
            </div>
          </div>
          <app-duke-alert
            data-test-submit-failed-message
            type="danger"
            alertClass="mb-0"
            *ngIf="isActiveFlowStep(totpStepKey) && hasError()"
            dismissible="true"
            (open)="alertHandler.onOpen($event);"
            (close)="alertHandler.onClose($event); genericError = undefined;"
          >
            <p class="mb-0" *ngIf="!tooManyAttemptsError">
              {{'login.submit-error.message.failed' | translate}}
            </p>
            <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttempts' | translate"></p>
            <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
            <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>
          </app-duke-alert>
          <ng-container [ngTemplateOutlet]="secondaryFlows"></ng-container>
        </div>
        <div class="card-footer">
          <div class="d-flex align-items-center flex-row-reverse">
            <span
              class="text-danger order-2 mr-2 text-right"
              *ngIf="showSignInFailed()"
              data-test-submit-failed-notification
            >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>
                  {{'login.submit-error.notification.failed' | translate}}
                </span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span  [innerHTML] = "'login.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }"></span>
              </ng-container>
            </span>
            <span
              class="text-muted order-2 mr-2 text-right"
              *ngIf="!showSignInFailed() && hasTooManyAttemptsTimeout()"
            >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span  [innerHTML] = "'login.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>

            <button
              data-test-next-button
              *ngIf="!isLastStep()"
              type="submit"
              class="btn btn-primary"
              [disabled]="!totpForm.valid"
            >
              {{'login.next-button.label' | translate}}
            </button>
            <button
              data-test-next-button
              *ngIf="isLastStep()"
              type="submit"
              class="btn btn-success"
              [disabled]="!totpForm.valid || hasTooManyAttemptsTimeout()"
            >
              {{'login.login-button.label' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="card-body" *ngIf="processingDelegated">
    <div class="loader-wrapper position-relative d-block mb-2">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
