/**
 * 10Duke Session API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddAuthenticationsResult } from '../model/addAuthenticationsResult';
import { ApiError } from '../model/apiError';
import { AuthenticationChallenges } from '../model/authenticationChallenges';
import { Credentials } from '../model/credentials';
import { UserCodeForDeviceAuthorizationDecisionRequired } from '../model/userCodeForDeviceAuthorizationDecisionRequired';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthenticationService {

    protected basePath = 'http://localhost:8080/api/0.exp';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds user authentications, or executes operation that requires giving authentication credentials
     * Adds one or more user authentications, for instance user name and password authentication, and a second factor authentication with a time-based one-time password. Also used for any operation that requires specifying authentication credentials, allowing similar security measures for protecting the credentials. Both successful and failed request may return authentication challenges as \&quot;WWW-Authenticate\&quot; header values if the user has not fully completed authentication. In this case, each header desribes an authentication flow available to the user, and user must complete one of the authentication flows.
     * @param body Array of authentication credentials. At least one credential object must by supplied.
     * @param remember Indicates if authenticated session created by the operation should be created as a persistent session
     * @param returnUser Instructs server to return data of the authenticated user in the response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAuthentications(body: Credentials, remember?: boolean, returnUser?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AddAuthenticationsResult>;
    public addAuthentications(body: Credentials, remember?: boolean, returnUser?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddAuthenticationsResult>>;
    public addAuthentications(body: Credentials, remember?: boolean, returnUser?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddAuthenticationsResult>>;
    public addAuthentications(body: Credentials, remember?: boolean, returnUser?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAuthentications.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (remember !== undefined && remember !== null) {
            queryParameters = queryParameters.set('remember', <any>remember);
        }
        if (returnUser !== undefined && returnUser !== null) {
            queryParameters = queryParameters.set('returnUser', <any>returnUser);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AddAuthenticationsResult>(`${this.basePath}/sessions/authentications`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets information of existing user authentications
     * Returns authentication challenge objects representing challenges responded by the session user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAuthentications(observe?: 'body', reportProgress?: boolean): Observable<AuthenticationChallenges>;
    public listAuthentications(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthenticationChallenges>>;
    public listAuthentications(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthenticationChallenges>>;
    public listAuthentications(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AuthenticationChallenges>(`${this.basePath}/sessions/authentications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
