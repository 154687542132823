import {Component, ElementRef, Inject, OnInit, ViewChild, AfterViewChecked, ViewEncapsulation} from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '../../gen/api/password.service';
import { SendEmailForResetPasswordRequest } from '../../gen/model/sendEmailForResetPasswordRequest';
import { Logger } from '../../util/logger';
import { AppConstants } from '../AppConstants';
import { StandaloneComponent } from '../standalone.component';
import { ParamsService } from '../params.service';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {cardFooterFeedbackTransition} from '../animations';
import LocationUtil, {UrlParams, getAppBaseHref} from '../../util/locationUtil';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../configuration-service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition,
  ],
})
export class ForgotPasswordComponent extends StandaloneComponent implements OnInit, AfterViewChecked {

  private updateFocus: boolean;

  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  @ViewChild('email', { read: ElementRef, static: true }) emailField: ElementRef;
  email: string;

  constructor(
    @Inject('PasswordApi') private passwordApi: PasswordService,
    private activatedRoute: ActivatedRoute,
    private paramsService: ParamsService,
    private translate: TranslateService,
    route: ActivatedRoute,
    router: Router,
    private alertHandler: AlertHandler,
    private logger: Logger,
    hostElement: ElementRef,
    configuration: ConfigurationService) {
    super(route, router, hostElement, configuration);
  }

  ngOnInit() {
    super.init();
    this.subscribeParamsState();
    this.updateFocus = true;
  }

  ngAfterViewChecked() {
    if (this.updateFocus) {
      StandaloneComponent.setFocusToElemOrChildControl(this.emailField);
      this.updateFocus = false;
    }
  }

  // noinspection JSMethodCanBeStatic
  hasError(field?: AbstractControl, code?: string): boolean {
    return false;
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  // noinspection JSMethodCanBeStatic
  isInputInvalid(field: AbstractControl): boolean {
    return field.invalid && field.touched;
  }

  isFieldValid(field: AbstractControl): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }

  // noinspection JSMethodCanBeStatic
  isInputValid(field: AbstractControl): boolean {
    return field.valid && field.touched;
  }

  submit() {
    const resetUri = [];
    resetUri.push(LocationUtil.getOwnDomainURL());
    resetUri.push(getAppBaseHref());
    resetUri.push(AppConstants.ROUTE_RESET_PASSWORD);
    if (resetUri[0].indexOf('?') < 0) {
      resetUri.push('?');
    } else {
      resetUri.push('&');
    }
    resetUri.push(AppConstants.QP_RESET_PASSWORD_KEY);
    resetUri.push('={0}&');
    resetUri.push(AppConstants.QP_EMAIL);
    resetUri.push('=');
    resetUri.push('{1}');
    resetUri.push(
      LocationUtil.validParamsToQueryString('&',
        [AppConstants.QP_EMAIL,  AppConstants.QP_RESET_PASSWORD_KEY],
        this.configuration.getProperties().allowedOriginsForNextParam)
    );
    const obj: SendEmailForResetPasswordRequest = {
      userName: this.forgotPasswordForm.get('email').value,
      resetUri: resetUri.join(''),
    };
    this.passwordApi.sendEmailForResetPassword(obj, 'response').subscribe(response => {
      this.router.navigate([AppConstants.PATH_RESET_PASSWORD], {
        relativeTo: this.route,
        queryParams: {
          [AppConstants.QP_EMAIL]: obj.userName,
        },
        queryParamsHandling: 'merge'
      });
    });
  }

  private subscribeParamsState() {
    this.activatedRoute.queryParams.subscribe(params => {
      const keyEmailParams = params[AppConstants.QP_EMAIL];
      let email;
      if (keyEmailParams) {
        if (Array.isArray(keyEmailParams)) {
          email = keyEmailParams[0];
        } else {
          email = keyEmailParams;
        }
      }
      this.email = email;
      if (this.email) {
        this.forgotPasswordForm.get('email').setValue(this.email);
      }
    });
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('forgot-password.window-title');
  }
}
