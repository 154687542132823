import {Inject, Injectable} from '@angular/core';
import {
  AcceptInvitationChallenge,
  AuthenticationChallenge,
  AuthenticationChallenges, AuthenticationFlow,
  AuthenticationFlows,
  AuthenticationFlowService,
  AuthenticationService, Credential, Credentials,
  SessionService, TimeBasedOneTimePasswordChallenge, TimeBasedOneTimePasswordCredential,
  User, UserForRegisterUser, UserNameAndPasswordChallenge, UserNameAndPasswordCredential, UserNameAndPasswordCredentialForResetPassword,
  UserService, VerifyLoginEmailChallenge, VerifyLoginEmailCredential
} from '../gen';
import {Logger} from '../util/logger';
import {BehaviorSubject, forkJoin, Observable, Subscription, throwError} from 'rxjs';
import {HttpErrorResponse, HttpResponse, HttpResponseBase} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import ResponseUtil from '../util/responseUtil';
import {ParamsService} from './params.service';
import {AppConstants} from './AppConstants';
import AuthenticationUtils from '../util/AuthenticationUtils';
import {CustomAcceptInvitationAuthenticationChallenge} from '../CustomAcceptInvitationAuthenticationChallenge';
import LocationUtil from '../util/locationUtil';
import {ConfigurationService} from './configuration-service';

const cloneDeep = require('lodash.clonedeep');

export interface ParamsForRouteNav {
  path: string;
  queryParams?: { [name: string]: string };
  fragment?: string;
}

class AppState {
  private authenticationState?: AuthenticationState;
  constructor(private logger: Logger, private configuration: ConfigurationService) {}

  private resolveQueryParams(removeParams?: Array<string>): { [name: string]: string } {
    const params = ParamsService.getParamsSync();
    if (!removeParams) {
      return params;
    }
    if (removeParams.indexOf('*') >= 0) {
      return {};
    }
    return Object.keys(params).reduce<{ [name: string]: string }>((map, name) => {
      if (removeParams.indexOf(name) < 0) {
        map[name] = params[name] as string;
      }
      return map;
    }, {});
  }

  private resolveRouteFromNextParam(): ParamsForRouteNav|string|undefined {
    let retVal: ParamsForRouteNav|string|undefined;
    const next = ParamsService.getParamSync(AppConstants.QP_NEXT);
    if (next) {
      if (next.startsWith('/') &&
        !next.startsWith('//')) {
        // is relative url
        if (!AppConstants.PATHS.filter((val) => val !== AppConstants.PATH_HOME && next.startsWith(val)).length) {
          // url is not a route path, inject own domain to form absolute url and navigate
          retVal = new URL(next, LocationUtil.getOwnDomainURL()).href;
        } else {
          // url is a route path, split to path, params and hash
          const params = {};
          let hash;
          let noParamUrl = next;
          if (next.indexOf('#') >= 0) {
            const splitted = next.split('#');
            hash = splitted[1];
            noParamUrl = splitted[0];
          }
          if (noParamUrl.indexOf('?') >= 0) {
            const splitted = noParamUrl.split('?');
            noParamUrl = splitted[0];
            const p = splitted[1].split('&');
            for (let i = 0; i < p.length; i += 1) {
              const t = p[i].split('=');
              params[t[0]] = t[1];
            }
          }
          retVal = {
            path: noParamUrl,
            queryParams: params,
            fragment: hash,
          };
        }
      } else if (LocationUtil.isValidNext(next, this.configuration.getProperties().allowedOriginsForNextParam)) {
        retVal = next;
      }
    }
    return retVal;
  }

  resolveNextRouteAfterLogout(removeParams?: Array<string>): ParamsForRouteNav|string {
    let rp: string[] = !!removeParams ? [].concat(removeParams) : removeParams;
    if (!this.getAuthenticationState() === undefined) {
      throw new Error('AppState.resolveNextRoute No authentication state');
    }
    const next = this.resolveRouteFromNextParam();
    if (next) {
      return next;
    } else if (!!rp && !rp.includes(AppConstants.QP_NEXT)) {
      // next potentially blocked, clean out of params
      rp.push(AppConstants.QP_NEXT);
    } else if (!rp) {
      rp = [AppConstants.QP_NEXT];
    }
    return {
      path: AppConstants.PATH_LOGIN,
      queryParams: this.resolveQueryParams(rp),
    };
  }
  resolveNextRoute(removeParams?: Array<string>): ParamsForRouteNav|string {
    this.logger.debug('AppState.resolveNextRoute %O', this);
    let rp: string[] = !!removeParams ? [].concat(removeParams) : removeParams;
    if (!this.getAuthenticationState() === undefined) {
      throw new Error('AppState.resolveNextRoute No authentication state');
    }
    if (!this.authenticationState.hasSession() || (
      !this.authenticationState.isFullyAuthenticated() &&
      this.authenticationState.getRemainingChallenges()
        .filter((val) => val.required)
        .filter((val) => {
          return AppConstants.LOGIN_COMPONENT_CHALLENGES.indexOf(val.authenticationMethod) >= 0;
        }).length > 0
    )) {
      // no session, or partial session with challenges that should be handled by login comp.
      return {
        path: AppConstants.PATH_LOGIN,
        queryParams: this.resolveQueryParams(rp),
      };
    }
    if (!this.authenticationState.isFullyAuthenticated() || !this.authenticationState.areOptionalsHandled()) {
      // required or optional challenges to handle
      let nextChallenge;
      const requiredAuthentications = this.authenticationState.getRemainingChallenges().filter((val) => val.required);
      const optionalAuthentications = this.authenticationState.getRemainingChallenges().filter((val) => !val.required);
      if (requiredAuthentications.length > 0) {
        nextChallenge = requiredAuthentications[0];
      } else if ((optionalAuthentications.length > 0)) {
        nextChallenge = optionalAuthentications[0];
      } else {
        throw new Error('AppState.resolveNextRoute No challenges to handle, should have fullyAuthenticated state');
      }
      let retVal: ParamsForRouteNav | string;
      const nextp = ParamsService.getParamSync(AppConstants.QP_NEXT);
      if (nextChallenge.authenticationMethod === AppConstants.AC_AM_VERIFY_EMAIL) {
        if (nextp && nextp.indexOf( AppConstants.PATH_VALIDATE_EMAIL) >= 0) {
          const nextU = new URL(nextp, window.location.href);
          const nextUp = {};
          nextU.searchParams.forEach((value, key) => {
            nextUp[key] = value;
          });
          return {
            path: AppConstants.PATH_VALIDATE_EMAIL,
            queryParams: nextUp,
            fragment: nextU.hash,
          };
        } else {
          retVal = {
            path: AppConstants.PATH_VALIDATE_EMAIL,
            queryParams: this.resolveQueryParams(rp),
          };
        }
      } else if (nextChallenge.authenticationMethod === AppConstants.AC_AM_INVITATION) {
        const ic = nextChallenge as AcceptInvitationChallenge;
        const ret = {
          path: AppConstants.PATH_JOIN,
          queryParams: {
            ...this.resolveQueryParams(rp),
            [AppConstants.QP_EMAIL]: ic.userName,
            [AppConstants.QP_INVITATION_ID]: ic.invitationId,
          },
        };
        if (nextp) {
          ret.queryParams[AppConstants.QP_NEXT] = nextp;
        }
        return ret;
      } else if (nextChallenge.authenticationMethod === AppConstants.AC_AM_INVITATION_ACCEPTED) {
        const ic = nextChallenge as CustomAcceptInvitationAuthenticationChallenge;
        const ret = {
          path: AppConstants.PATH_JOIN,
          queryParams: {
            ...this.resolveQueryParams(rp),
            [AppConstants.QP_EMAIL]: ic.email,
            [AppConstants.QP_INVITATION_TOKEN]: ic.token,
            [AppConstants.QP_HANDLE_CHALLENGE]: AppConstants.AC_AM_INVITATION_ACCEPTED,
            [AppConstants.QP_REQUIRE_CHALLENGE]: null,
          },
        };
        if (nextp) {
          ret.queryParams[AppConstants.QP_NEXT] = nextp;
        }
        return ret;
      } else {
        throw new Error('AppState.resolveNextRoute unknown unhandled challenge: ' + nextChallenge.authenticationMethod);
      }
      return retVal;
    }
    // fully authenticated, all challenges handled
    const next = this.resolveRouteFromNextParam();
    if (next) {
      return next;
    } else if (!!rp && !rp.includes(AppConstants.QP_NEXT)) {
      // next url potentially blocked, clean out of params
      rp.push(AppConstants.QP_NEXT);
    } else if (!rp) {
      rp = [AppConstants.QP_NEXT];
    }
    return {
      path: AppConstants.PATH_PROFILE,
      queryParams: this.resolveQueryParams(rp),
    };
  }

  updateAuthenticationState(auth: AuthenticationState) {
    this.authenticationState = auth;
  }

  getAuthenticationState(): AuthenticationState|undefined {
    return this.authenticationState;
  }

  isInitialized(): boolean {
    return !!this.authenticationState;
  }
}
interface AuthenticationStateData {
  /** Current user authentications */
  existingAuthentications: Array<AuthenticationChallenge>;
  /** Default authentication flows, or authentication flows available to the user if there is an existing session user */
  authenticationFlows: AuthenticationFlows;
  profile?: User;
}
export class AuthenticationState {
  private profile?: User;
  private skippedInvitations: Array<string> = [];
  private acceptedInvitations: Array<string> = [];
  private declinedInvitations: Array<string> = [];
  /** Current user authentications */
  existingAuthentications: Array<AuthenticationChallenge>;
  /** Default authentication flows, or authentication flows available to the user if there is an existing session user */
  authenticationFlows: AuthenticationFlows;
  constructor(obj?: AuthenticationStateData) {
    if (obj) {
      this.existingAuthentications = obj.existingAuthentications;
      this.authenticationFlows = obj.authenticationFlows;
      this.profile = obj.profile;
    }
  }
  getHandledInvitations(): Array<string> {
    return [].concat(this.getSkippedInvitations()).concat(this.getAcceptedInvitations()).concat(this.getDeclinedInvitations());
  }
  getSkippedInvitations(): Array<string> {
    return this.skippedInvitations;
  }
  setSkippedInvitations(s: Array<string>)  {
    this.skippedInvitations = s;
  }
  public skipInvitation(id: string) {
    if (this.getSkippedInvitations().indexOf(id) < 0) {
      this.getSkippedInvitations().push(id);
    }
  }
  getAcceptedInvitations(): Array<string> {
    return this.acceptedInvitations;
  }
  setAcceptedInvitations(s: Array<string>)  {
    this.acceptedInvitations = s;
  }
  public acceptInvitation(id: string) {
    if (this.getAcceptedInvitations().indexOf(id) < 0) {
      this.getAcceptedInvitations().push(id);
    }
  }
  getDeclinedInvitations(): Array<string> {
    return this.declinedInvitations;
  }
  setDeclinedInvitations(s: Array<string>)  {
    this.declinedInvitations = s;
  }
  public declineInvitation(id: string) {
    if (this.getDeclinedInvitations().indexOf(id) < 0) {
      this.getDeclinedInvitations().push(id);
    }
  }
  getProfile(): User {
    return this.profile;
  }
  setProfile(u: User) {
    this.profile = u;
  }
  getAuthenticationFlows(): AuthenticationFlows {
    return this.authenticationFlows;
  }

  getExistingAuthentications(): Array<AuthenticationChallenge> {
    return this.existingAuthentications;
  }
  addExistingAuthentication(a: AuthenticationChallenge) {
    this.getExistingAuthentications().push(a);
  }
  addExistingAuthenticationChallengeByCompletedCredential(c: Credential) {
    const am = c.authenticationMethod;
    const challenge: AuthenticationChallenge = {
      authenticationMethod: am,
      required: true,
    };
    if (am === AppConstants.AC_AM_PASSWORD) {
      const c2 = c as UserNameAndPasswordCredential;
      delete c2.password;
      const challenge2: UserNameAndPasswordChallenge = { ...challenge, ...c2 };
      this.addExistingAuthentication(challenge2);
    } else if (am === AppConstants.AC_AM_VERIFY_EMAIL) {
      const c2 = c as VerifyLoginEmailCredential;
      delete c2.verificationCode;
      const challenge2: VerifyLoginEmailChallenge = { ...challenge, ...c2 };
      this.addExistingAuthentication(challenge2);
    } else if (am === AppConstants.AC_AM_TOTP) {
      const c2 = c as TimeBasedOneTimePasswordCredential;
      delete c2.oneTimePassword;
      const challenge2: TimeBasedOneTimePasswordChallenge = { ...challenge, ...c2 };
      this.addExistingAuthentication(challenge2);
    } else if (am === AppConstants.AC_AM_RESET_PWD) {
      const c2 = c as UserNameAndPasswordCredentialForResetPassword;
      delete c2.code;
      delete c2.password;
      // reset pwd is responded with AppConstants.AC_AM_PASSWORD, so override default authenticationMethod
      c2.authenticationMethod = AppConstants.AC_AM_PASSWORD;
      const challenge2: UserNameAndPasswordChallenge = { ...challenge, ...c2 };
      this.addExistingAuthentication(challenge2);
    } else if (am !== AppConstants.AC_AM_USER_CODE) {
      // The device authentication does not need to be stored in state
      throw new Error('AuthenticationState.addExistingAuthenticationChallengeByCompletedCredential: UnsupportedAuthenticationMethod ' + am);
    }
    /* else if (am === AppConstants.AC_AM_PASSWORD) {
      const c2 = c as UserNameAndPasswordCredential;
      delete c2.password;
      const challenge2: UserNameAndPasswordChallenge = { ...challenge, ...c2 };
      this.addExistingAuthentication(challenge2);
    } */
  }

  /**
   * Adds provided challenges to most completed auth flow. Filters out challenges that already exist in a flow.
   * @param c The challenges
   */
  addChallengesToMostCompletedFlow(c: Array<AuthenticationChallenge>) {
    const flows = this.getAuthenticationFlows();
    const missingChallenges = c.filter((val) => {
      if (flows && flows.length > 0) {
        for (let i = 0; i < flows.length; i += 1) {
          let aChall: AuthenticationFlow | AuthenticationChallenge = flows[i];
          while (aChall.challenges && aChall.challenges.length > 0) {
            aChall = aChall.challenges[0];
            if (aChall.authenticationMethod === val.authenticationMethod) {
              if (aChall.authenticationMethod === AppConstants.AC_AM_INVITATION) {
                if ((aChall as AcceptInvitationChallenge).invitationId === (val as AcceptInvitationChallenge).invitationId) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }
          }
        }
      }
      return true;
    });
    const currentFlow: AuthenticationFlow = AuthenticationUtils.findMostCompletedBranchedFlow(
      flows,
      this.getExistingAuthentications())
    ;
    let chall: AuthenticationFlow|AuthenticationChallenge = currentFlow;
    while (chall.challenges && chall.challenges.length > 0) {
      chall = chall.challenges[0];
    }
    if (chall) {
      while (missingChallenges.length) {
        if (!chall.challenges) {
          chall.challenges = [];
        }
        chall.challenges.push(missingChallenges.shift());
        chall = chall.challenges[0];
      }
    }
  }
  addExistingAuthenticationChallengesByCompletedCredentials(c: Credentials) {
    c.forEach((val) => this.addExistingAuthenticationChallengeByCompletedCredential(val));
  }

  hasSession(): boolean {
    return this.existingAuthentications && this.existingAuthentications.length > 0;
  }

  /**
   * Resolves remaining challenges based on implicitly chosen authentication flow and completed challenges
   */
  getRemainingChallenges(): Array<AuthenticationChallenge> {
    const nextChallenge = AuthenticationUtils.findNextChallenge(this.authenticationFlows, this.existingAuthentications);
    const additionalRequirement = ParamsService.getParamSync(AppConstants.QP_REQUIRE_CHALLENGE);
    let additionalChallenge = null;
    if (additionalRequirement === AppConstants.AC_AM_INVITATION_ACCEPTED) {
      additionalChallenge = {
        token: ParamsService.getParamSync(AppConstants.QP_INVITATION_TOKEN),
        email: ParamsService.getParamSync(AppConstants.QP_EMAIL),
        authenticationMethod: AppConstants.AC_AM_INVITATION_ACCEPTED,
        required: true,
      } as CustomAcceptInvitationAuthenticationChallenge;
    } else if (additionalRequirement) {
      throw new Error('AppStateService.getRemainingChallenges: Unsupported additional requirement ' +
        additionalRequirement
      );
    }
    const challenges: Array<AuthenticationChallenge> = [];
    let additionalRequirementAdded = false;
    if (nextChallenge) {
      let chall = nextChallenge;
      while (chall) {
        if (!additionalRequirementAdded && additionalRequirement === AppConstants.AC_AM_INVITATION_ACCEPTED &&
          chall.authenticationMethod === AppConstants.AC_AM_INVITATION
        ) {
          // inject accepted invitation challenge before first returned invitation challenge.
          challenges.push(additionalChallenge);
          additionalRequirementAdded = true;
        }
        if (chall.authenticationMethod !== AppConstants.AC_AM_INVITATION || (this.getHandledInvitations().indexOf(
          (chall as AcceptInvitationChallenge).invitationId) < 0)) {
            challenges.push(chall);
        }
        //
        chall = chall.challenges ? chall.challenges[0] : null;
      }
      if (!additionalRequirementAdded && additionalRequirement) {
        challenges.push(additionalChallenge);
      }
    } else if (additionalRequirement) {
      challenges.push(additionalChallenge);
    }
    return challenges;
  }

  isFullyAuthenticated(): boolean {
    return this.getRemainingChallenges().filter((val) => val.required).length === 0;
  }
  areOptionalsHandled(): boolean {
    return this.getRemainingChallenges().filter((val) => !val.required).length === 0;
  }

}
@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  public static USERNAME_FOR_FLOW_CONFIGURATION = '_';
  private appState: AppState;
  private serviceInitSubject: BehaviorSubject<AppStateService> = new BehaviorSubject<AppStateService>(this);
  constructor(
    @Inject('AuthenticationApi') private authenticationApi: AuthenticationService,
    @Inject('AuthenticationFlowApi') private authenticationFlowApi: AuthenticationFlowService,
    @Inject('UserApi') private userApi: UserService,
    @Inject('SessionApi') private sessionApi: SessionService,
    private logger: Logger,
    private configuration: ConfigurationService) {
    this.appState = new AppState(logger, this.configuration);
  }
  isAppInitialized() {
    return this.serviceInitSubject.isStopped;
  }
  init(cb) {
    this.serviceInitSubject.subscribe(undefined, undefined, cb);
    this.fetchAuthenticationState(undefined, (val: AuthenticationState) => {
      const handler = () => {
        this.appState.updateAuthenticationState(val);
        this.serviceInitSubject.next(this);
        if (this.appState.isInitialized() && !this.serviceInitSubject.isStopped) {
          this.serviceInitSubject.complete();
        }
      };
      if (val.hasSession()) {
        this.userApi.getUser('response').subscribe((resp) => {
          if (resp && resp.body) {
            val.setProfile(resp.body as User);
            handler();
          }
        }, (error) => {
          handler();
        });
      } else {
        handler();
      }
    }, (err: any) => {
      throw new Error('AppStateService.init fetch error' + err);
    });
  }

  subscribeInitState(cb): Subscription {
    if (!this.serviceInitSubject.isStopped) {
      return this.serviceInitSubject.subscribe(cb);
    }
    // Looks like the behavioursubject is not working correctly after completion, so we must invoke the callback directly
    setTimeout(() => { cb(this); }, 1);
  }

  getAppState() {
    return this.appState;
  }

  public registerUser(
    body: UserForRegisterUser,
    verifyEmail?: boolean,
    verifyEmailUri?: string,
    remember?: boolean
  ): Observable<HttpResponse<any>> {
    return new Observable<HttpResponse<any>>(subscriber => {
      const t: Observable<HttpResponse<any>> = this.userApi.registerUser(body, verifyEmail, verifyEmailUri, remember, 'response');
      t.subscribe(resp => {
        const usr = cloneDeep(body);
        // Registration gives the user a pwd authentication, so we create a dummy credential to match.
        const cred: UserNameAndPasswordCredential = {
          authenticationMethod: AppConstants.AC_AM_PASSWORD,
          userName: usr.email,
          password: usr.password,
        } as UserNameAndPasswordCredential;
        delete usr.password;

        this.fetchAuthenticationState(usr.email, (auth: AuthenticationState) => {
          this.getAppState().updateAuthenticationState(auth);


          this.getAppState().getAuthenticationState().setProfile(usr);
          // not sure if this is really needed, or do we only get here if it's not required.
          this.getAppState().getAuthenticationState().addExistingAuthenticationChallengeByCompletedCredential({
            verificationCode: 'foobar',
            authenticationMethod: AppConstants.AC_AM_VERIFY_EMAIL,
          } as VerifyLoginEmailCredential);
          this.getAppState().getAuthenticationState().addExistingAuthenticationChallengesByCompletedCredentials([cred]);
          subscriber.next(resp);
        });
      }, err => {

        const usr = cloneDeep(body);
        // Registration gives the user a pwd authentication, so we create a dummy credential to match.
        const cred: UserNameAndPasswordCredential = {
          authenticationMethod: AppConstants.AC_AM_PASSWORD,
          userName: usr.email,
          password: usr.password,
        } as UserNameAndPasswordCredential;
        delete usr.password;

        this.fetchAuthenticationState(usr.email, (auth: AuthenticationState) => {
          this.getAppState().updateAuthenticationState(auth);
          this.getAppState().getAuthenticationState().setProfile(usr);

          if (!err || !err.error || !err.error.error) {
            const reqChallenges = ResponseUtil.readChallenges(err).required;
            const filteredCredentials = !reqChallenges.find((val) => val.authenticationMethod === AppConstants.AC_AM_PASSWORD) ?
              [cred] : [];
            if (!reqChallenges.find((val) => val.authenticationMethod === AppConstants.AC_AM_VERIFY_EMAIL)) {
              // email verification not required in headers, so it has been taken care of in previous sessions and we can hack the state by
              // adding a dummy completed challenge into state
              this.getAppState().getAuthenticationState().addExistingAuthenticationChallengeByCompletedCredential({
                verificationCode: 'foobar',
                authenticationMethod: AppConstants.AC_AM_VERIFY_EMAIL,
              } as VerifyLoginEmailCredential);
            }
            this.getAppState().getAuthenticationState().addExistingAuthenticationChallengesByCompletedCredentials(filteredCredentials);
          }
          subscriber.error(err);
        });
      });
    });
  }

  public addAuthentications(credentials: Credentials, remember?: boolean, returnUser?: boolean): Observable<HttpResponse<any>> {
    return new Observable<HttpResponse<any>>(subscriber => {
      const t: Observable<HttpResponse<any>> = this.authenticationApi.addAuthentications(
        credentials, remember, returnUser, 'response');
      t.subscribe(response => {
        const reqChallenges = ResponseUtil.readChallenges(response);

        this.getAppState().getAuthenticationState()
          .addChallengesToMostCompletedFlow([].concat(reqChallenges.required).concat(reqChallenges.optional));

        this.getAppState().getAuthenticationState().addExistingAuthenticationChallengesByCompletedCredentials(credentials);
        // email verification not required in headers, so it has been taken care of in previous sessions and we can hack the state by
        // adding a dummy completed challenge into state
        this.getAppState().getAuthenticationState().addExistingAuthenticationChallengeByCompletedCredential({
          verificationCode: 'foobar',
          authenticationMethod: AppConstants.AC_AM_VERIFY_EMAIL,
        } as VerifyLoginEmailCredential);
        // add email validated chall
        if (response && response.body && response.body.user) {
          this.getAppState().getAuthenticationState().setProfile(response.body.user as User);
        }
        subscriber.next(response);
      }, err => {
        if (err && err.error && err.error.user) {
          this.getAppState().getAuthenticationState().setProfile(err.error.user as User);
        }
        if (!err || !err.error || !err.error.error) {
          const reqChallenges = ResponseUtil.readChallenges(err);
          this.getAppState().getAuthenticationState()
            .addChallengesToMostCompletedFlow([].concat(reqChallenges.required).concat(reqChallenges.optional));

          const filteredCredentials = credentials.filter((val) => {
            return !reqChallenges.required.find((v) => {
              return v.authenticationMethod === val.authenticationMethod;
            });
          });
          if (!reqChallenges.required.find((val) => val.authenticationMethod === AppConstants.AC_AM_VERIFY_EMAIL)) {
            // email verification not required in headers, so it has been taken care of in previous sessions and we can hack the state by
            // adding a dummy completed challenge into state
            this.getAppState().getAuthenticationState().addExistingAuthenticationChallengeByCompletedCredential({
              verificationCode: 'foobar',
              authenticationMethod: AppConstants.AC_AM_VERIFY_EMAIL,
            } as VerifyLoginEmailCredential);
          }
          this.getAppState().getAuthenticationState().addExistingAuthenticationChallengesByCompletedCredentials(filteredCredentials);
          subscriber.next(err);
        } else {
          subscriber.error(err);
        }
      });
    });
  }
  public updateUser(body: User): Observable<HttpResponse<any>> {
    return new Observable<HttpResponse<any>>(subscriber => {
      const t = this.userApi.updateUser(body, 'response');
      t.subscribe((resp) => {
        this.getAppState().getAuthenticationState().setProfile(body);
        subscriber.next(resp);
      }, (err) => {
        subscriber.error(err);
      });
    });
  }
  public endSession(ignoreConsumerSingleSignOut?: boolean, ignoreProviderSingleSignOut?: boolean): Observable<HttpResponse<any>> {
    return new Observable<HttpResponse<any>>(subscriber => {
      const t: Observable<HttpResponse<any>> = this.sessionApi.endSession(
        ignoreConsumerSingleSignOut,
        ignoreProviderSingleSignOut,
        'response'
      );
      t.subscribe(response => {
        this.fetchAuthenticationState(undefined, (val: AuthenticationState) => {
          this.getAppState().updateAuthenticationState(val);
          subscriber.next(response);
        });
      }, err => {
        this.fetchAuthenticationState(undefined, (val: AuthenticationState) => {
          this.getAppState().updateAuthenticationState(val);
          subscriber.error(err);
        });
      });
    });
  }
  public fetchAuthenticationState(
    username?: string,
    callback?: (val: AuthenticationState) => void,
    errorCallback?: (err: any) => void) {
    const listExistingAuthenticationsRequest: Observable<HttpResponseBase> =
      this.authenticationApi.listAuthentications('response')
        .pipe(catchError(error => ResponseUtil.handleAuthenticationChallengeResponse(error)));

    const listUserAuthenticationFlowsRequest: Observable<HttpResponseBase> =
      this.authenticationFlowApi.listUserAuthenticationFlows(username || '', 'response')
        .pipe(catchError(error => throwError(error)));

    const bundledRequests = forkJoin([listExistingAuthenticationsRequest, listUserAuthenticationFlowsRequest]);

    bundledRequests.subscribe(responses => {
      // first handle the listUserAuthenticationFlowsRequest
      const listUserAuthenticationFlowsResponse: any = responses[1];
      const userAuthenticationFlows: AuthenticationFlows = listUserAuthenticationFlowsResponse.body;
      this.logger.debug('AppStateService.fetchAuthenticationState flows: %O', userAuthenticationFlows || []);

      // second handle the listExistingAuthenticationsRequest
      const listExistingAuthenticationsResponse = responses[0];
      let existingAuthenticationObjs: AuthenticationChallenges = null;

      if (listExistingAuthenticationsResponse instanceof HttpResponse) {
        existingAuthenticationObjs = (listExistingAuthenticationsResponse as HttpResponse<AuthenticationChallenges>).body;
      } else if (listExistingAuthenticationsResponse instanceof HttpErrorResponse) {
        existingAuthenticationObjs = (listExistingAuthenticationsResponse as HttpErrorResponse).error as AuthenticationChallenges;
      } else {
        existingAuthenticationObjs = [];
      }
      this.logger.debug('AppStateService.fetchAuthenticationState authentications: %O', existingAuthenticationObjs);

      const authState: AuthenticationState = new AuthenticationState({
        authenticationFlows: userAuthenticationFlows,
        existingAuthentications: existingAuthenticationObjs,
        profile: {
          email: username,
          firstName: '',
          lastName: '',
        } as User,
      });
      this.logger.debug('AppStateService.fetchAuthenticationState Required authentications: %O',
        authState.getRemainingChallenges().filter((val) => val.required));
      this.logger.debug('AppStateService.fetchAuthenticationState Optional authentications: %O',
        authState.getRemainingChallenges().filter((val) => !val.required));
      this.logger.debug('AppStateService.fetchAuthenticationState ' +
        ' authentication status:\n - hasSession: %O \n - fullyAuthenticated: %O \n - optionalsHandled: %O',
        authState.hasSession(),
        authState.isFullyAuthenticated(),
        authState.areOptionalsHandled())
      ;

      if (callback) {
        callback(authState);
      }
    }, (error) => {
      if (errorCallback) {
        errorCallback(error);
      } else {
        throw(error);
      }

    });
  }

}
