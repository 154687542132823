import { AfterContentInit, ElementRef, EventEmitter, HostBinding, Output, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from './AppConstants';
import {Observable, of} from 'rxjs';
import {ConfigurationService} from './configuration-service';

@Directive()
export class StandaloneComponent implements AfterContentInit {
  @Output() initialized: EventEmitter<boolean> = new EventEmitter();
  @HostBinding('class') hostClass = 'order-2';
  /**
   * Ready state of the component for init animations/UI triggers.
   * Set to true once the component has been drawn
   */
  private ready: boolean;

  protected route: ActivatedRoute;

  protected router: Router;

  protected configuration: ConfigurationService;

  protected hostElement: ElementRef;

  protected focusSelectorPrefix = '.card-body';

  public static setFocusToElemOrChildControl(elem: ElementRef, preferredContainerSelector?: string) {
    let toFocus;
    let prefix: string = preferredContainerSelector || '';
    if (prefix.length > 0) {
      prefix = prefix + ' ';
    }
    const focusables = [
      prefix + 'button',
      prefix + '[href]',
      prefix + 'input',
      prefix + 'select',
      prefix + 'textarea',
      prefix + '[tabindex]:not([tabindex="-1"])',
    ];
    if (elem) {
      const t = [].concat(
        Array.from(elem.nativeElement.querySelectorAll(focusables.join(', ')))
      );
      if (t && t.length) {
        for (let i = 0; i < t.length; i += 1) {
          if (!t[i].disabled && !t[i].hidden && !t[i].readOnly) {
            toFocus = t[i];
            break;
          }
        }
      }
    }
    if (!toFocus) {
      if (preferredContainerSelector) {
        const t = [].concat(
          Array.from(elem.nativeElement.querySelectorAll(preferredContainerSelector))
        );
        if (t && t.length) {
          for (let i = 0; i < t.length; i += 1) {
            if (!t[i].disabled && !t[i].hidden) {
              toFocus = t[i];
              break;
            }
          }
        }
        if (!toFocus) {
          toFocus = elem.nativeElement;
        }
      } else {
        toFocus = elem.nativeElement;
      }
    }
    setTimeout(() => toFocus.focus({
      preventScroll: true
    }), 1);
  }

  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    configuration: ConfigurationService,
  ) {
    this.route = route;
    this.router = router;
    this.hostElement = hostElement;
    this.configuration = configuration;
  }
  ngAfterContentInit() {
    setTimeout(() => {
      StandaloneComponent.setFocusToElemOrChildControl(this.hostElement, this.focusSelectorPrefix);
    }, 1);
    this.initialized.emit(true);
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return of(undefined);
  }
  public isIEorEDGE(): boolean {
    const retVal: boolean = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    return retVal;
  }
  protected init() {
    this.setReady();
  }

  protected setReady() {
    setTimeout(() => {
      this.ready = true;
    }, 1);
  }

  isReady(): boolean {
    return this.ready;
  }

  // noinspection JSMethodCanBeStatic
  wrapAsObject(l: any[]): any {
    const retValue = {};
    if (l && l.length && l.length % 2 === 0) {
      for (let i = 0; i < l.length; i += 2) {
        retValue[l[i]] = l[i + 1];
      }
    } else {
      throw new Error('Invalid arguments');
    }
    return retValue;
  }

  // noinspection JSMethodCanBeStatic
  getAppConstant(name: string): any {
    return AppConstants[name];
  }
}
