/**
 * Describes a field to use in forms
 */
export interface SsoUIField {
    /**
     * Identifier of the field, maps to object model fields, for hierarchical references a dash `-` is used as separator
     * eg. `address-country`
     */
    key: string;
    /**
     * Defines if a value for the field is required, used for form validation
     */
    required?: boolean;

    /**
     * Defines if a value is editable, used for enabling ui features
     */
    readonly?: boolean;

}

/**
 * Describes a configuration containing configurable set of fields.
 */
export interface SsoUIFieldConfiguration {
    /**
     * Fields to include, in order of inclusion.
     * If missing, default set is used. If required fields are missing from conf, they are added, but an error is shown.
     * Minimum set of required fields and full set of supported fields are resolved at runtime based on the OpenAPI description.
     */
    fields?: SsoUIField[];
}

/**
 * Describes the configuration for user registration
 */
export interface SsoUIRegistrationConfiguration extends SsoUIFieldConfiguration {
    /**
     * Controls automatic sending of validation email for successful registrations.
     * If missing, default is used.
     */
    'send-validation-email'?: boolean;
}

/**
 * Describes the configuration for the profile section. Used also for controlling other visualizations of user data.
 * ie. if a profile field does not exist in one the field configurations here, it's not shown anywhere in the app.
 */
export interface SsoUIProfileConfiguration {

    /**
     * Field configuration for the Personal section
     * If missing, default set is used. If required fields are missing from conf, they are added, but an error is shown.
     * Minimum set of required fields and full set of supported fields are resolved at runtime based on the OpenAPI description.
     */
    personal?: SsoUIFieldConfiguration;

    /**
     * Field configuration for the Contact section
     * If missing, default set is used. If required fields are missing from conf, they are added, but an error is shown.
     * Minimum set of required fields and full set of supported fields are resolved at runtime based on the OpenAPI description.
     */
    contact?: SsoUIFieldConfiguration;

    /**
     * Field configuration for the Login section
     * If missing, default set is used. If required fields are missing from conf, they are added, but an error is shown.
     * Minimum set of required fields and full set of supported fields are resolved at runtime based on the OpenAPI description.
     */
    login?: SsoUIFieldConfiguration;
}

/**
 * Describes a locale description item to use with locale switcher
 */
export interface SsoUILocaleSwitcherLocale {
    /**
     * The locale string, eg. 'en', 'fi' TODO: check and document format restrictions if any.
     */
    value: string;
    /**
     * Name of the locale/language in that language, eg. 'English', 'Suomi'
     */
    label: string;
}

/**
 * Describes the configuration for the locale switcher
 */
export interface SsoUILocaleSwitcherConfiguration {
  /**
   * Toggles the availability of the tool.
   * Defaults are used if missing.
   */
  'show-switcher'?: boolean;
  /**
   * Defiens the default locale
   */
  default?: string;
  /**
   * Defines the locales to include in the tool.
   * Please note that there is no guarantee that resources for those locales exist. It is the responsibility
   * of the developer to add matching resource files separately, this only controls what is shown in tool.
   * If missing, default set is used. Empty array is considered as undefined and ignored.
   */
  locales?: SsoUILocaleSwitcherLocale[];
}

/**
 * Describes the configuration for the login to continue notification
 * Defined as interface, as this may be extended to support eg. regex pattern for showing the notification depending on the next param.
 */
export interface SsoUILoginToContinueNotificationConfiguration {
  /**
   * Toggles the availability of the notification.
   * Default is used if missing.
   */
  show?: boolean;
}
/**
 * Describes the configuration for the pending action notification
 * Defined as interface, as this may be extended to support eg. regex pattern for showing the notification depending on the action.
 */
export interface SsoUIPendingActionNotificationConfiguration {
  /**
   * Toggles the availability of the notification.
   * Default is used if missing.
   */
  show?: boolean;
};

/**
 * Describes the topics that can be configured
 */
export interface SsoUIFunctionalityConfiguration {

  /**
   * Configuration for the Registration process
   * If missing, defaults are used.
   */
  registration?: SsoUIRegistrationConfiguration;

  /**
   * Configuration for the Profile section
   * If missing, defaults are used.
   */
  profile?: SsoUIProfileConfiguration;

  /**
   * Configuration for the Locale switcher tool
   * If missing, defaults are used.
   */
  'localization'?: SsoUILocaleSwitcherConfiguration;

  /**
   * Configuration for the Login to continue notification
   * If missing, default is used.
   */
  'login-to-continue-notification'?: SsoUILoginToContinueNotificationConfiguration;

  /**
   * Configuration for the pending action notification
   * If missing, default is used.
   */
  'pending-action-notification'?: SsoUIPendingActionNotificationConfiguration;

  /**
   * Toggles the display of email validation code input and related copy
   * If missing, default is used.
   */
  'show-email-validation-code-input'?: boolean;

  /**
   * Toggles the display of reset password code input and related copy
   * If missing, default is used.
   */
  'show-reset-password-code-input'?: boolean;

  /**
   * Toggles the display of reset password fields if no code
   * If missing, default is used.
   */
  'show-reset-password-fields-without-code'?: boolean;


  /**
   * Defines a list of origins (scheme + host + port) that can be used with next param redirects, only http, https or relative (empty)
   * schemes are allowed. Own origin is always allowed.
   */
  'allowed-origins-for-next-param'?: string[];
}

export enum SsoUIOutputType {
  /**
   * The full UI as originally intended
   */
  DEFAULT = 'default',
  /**
   * Limitied view for embedding into context that provides part(s) of the functionality & user experience.
   * Removes header, footer and session panel along with all functionality provided by them (eg. logout, change languge)
   */
  CONTENT_ONLY = 'content-only',
}

/**
 * Configuration for the SsoUIDefault Webapp
 */
export interface SsoUIConfiguration {
  /**
   * The version of the app
   */
  version?: string;
  /**
   * Name of the application/service. To be used when ever the service is being identified as text. If left out, falls back to the
   * `service-name` in `branding.json` or finally the `service-name` defined in default conf.
   */
  'service-name'?: string;

  /**
   * Output style of the app. Controls which parts are included.
   */
  output?: SsoUIOutputType;

  /**
   * Wraps the actual configurable topics
   * If missing, defaults are used.
   */
  functionality?: SsoUIFunctionalityConfiguration;
}
/* tslint:disable:quotemark */
/**
 * Intentionally defined in a JSON compatible manner for easy copy-paste base for configs
 */
export const DEFAULT_UI_CONFIGURATION: SsoUIConfiguration = {
    "version": "0.1.1",
    "service-name": "10Duke Identity",
    "output": "default",
    "functionality": {
        "registration": {
            "send-validation-email": true,
            "fields": [
                { "key": "firstName", "required": true },
                { "key": "lastName", "required": true },
                { "key": "email", "required": true },
                { "key": "password", "required": true },
                { "key": "confirmPassword", "required": true },
                { "key": "address-country", "required": true },
                { "key": "acceptTsAndCs", "required": true }
            ]
        },
        "profile": {
            "personal": {
                "fields": [
                    { "key": "firstName", "required": true },
                    { "key": "lastName", "required": true },
                    { "key": "professionalTitle", "required": false },
                    { "key": "nickname", "required": false },
                    { "key": "preferredUsername", "required": false }
                ]
            },
            "contact": {
                "fields": [
                  { "key": "address-streetAddress", "required": false },
                  { "key": "address-postalCode", "required": false },
                  { "key": "address-locality", "required": false },
                  { "key": "address-region", "required": false },
                  { "key": "address-country", "required": true }
                ]
            },
            "login": {
                "fields": [
                    { "key": "email", "required": true },
                    { "key": "password", "required": true },
                    { "key": "totp", "required": false }
                ]
            }
        },
        "localization": {
            "show-switcher": true,
            "default": "en",
            "locales": [
                { "value": "cs", "label": "Čeština" },
                { "value": "da", "label": "Dansk" },
                { "value": "de", "label": "Deutsch" },
                { "value": "en", "label": "English" },
                { "value": "en-gb", "label": "English (British)" },
                { "value": "es", "label": "Español" },
                { "value": "fr", "label": "Français" },
                { "value": "it", "label": "Italiano" },
                { "value": "nl", "label": "Nederlands" },
                { "value": "no", "label": "Norsk" },
                { "value": "pt", "label": "Português" },
                { "value": "sk", "label": "Slovenčina" },
                { "value": "fi", "label": "Suomi" },
                { "value": "sv", "label": "Svenska" }
            ]
        },
        "login-to-continue-notification": {
          "show": true
        },
        "pending-action-notification": {
          "show": true
        },
        "show-email-validation-code-input": false,
        "show-reset-password-code-input": true,
        "show-reset-password-fields-without-code": true,
        "allowed-origins-for-next-param": []
    }
} as SsoUIConfiguration;
/* tslint:enable:quotemark */
