<div
  class="session-panel my-3"
  *ngIf="showUI() && showSessionPanel && isUserAuthenticated() && getProfile()"
>
  <button
    data-test-session-panel-trigger
    class="btn btn-sessiopanel-trigger"
    type="button"
    placement="bottom-right"
    (click)="openSessionPanel()"
  >
    <span class="profile-picture align-middle">{{resolveLoggedInUserName().length ? resolveLoggedInUserName().substr(0, 1) : resolveLoggedInUserName()}}</span>
    <span class="fa fa-fw fa-bars align-middle"></span>
  </button>
  <div
    data-test-session-panel
    class="popover bs-popover-bottom bs-popover-bottom-right{{sessionPanelOpen ? ' show' : ''}}"
    role="tooltip"
    (appClickOutside)="sessionPanelOpen ? sessionPanelOpen = false : null"
  >
    <div class="arrow"></div>
    <h3 class="popover-header">
      <div class="d-flex flex-row align-items-center">
        <span class="profile-picture mr-2 align-middle flex-shrink-0">{{resolveLoggedInUserName().length ? resolveLoggedInUserName().substr(0, 1) : resolveLoggedInUserName()}}</span>
        <span>
            {{resolveLoggedInUserName()}}
          </span>
      </div></h3>
    <div class="popover-body">
      <button
        data-test-session-panel-signout-trigger
        class="btn btn-danger btn-block" (click)="sessionPanelOpen = false; signOut()">
        {{'app.sign-out-button.label' | translate}}
      </button>
    </div>
  </div>
</div>
<article
  data-iframe-height
  class="container d-flex flex-column"
  [@routeAnimations]="prepareRouteTransition(routerOutlet)"
  [@.disabled]="disableAnimations()"
  *ngIf="showUI()"
>
  <div *ngIf="showLoginBeforeNext()" class="nextNotification">
    <app-duke-alert
      type="warning"
      icon="fa-user-lock"
      [dismissible]="true"
      alertClass="floating"
      data-test-login-before-next
      (close)="dismissLoginBeforeNext()"
    >
      <p class="mb-0">
        {{ 'app.loginBeforeNext' | translate: { label: resolveNextLabel(translate) } }}
      </p>
    </app-duke-alert>
  </div>
  <div *ngIf="showPendingActionNotification()" class="pendingActionNotification">
    <app-duke-alert
      type="warning"
      icon="fa-user-lock"
      [dismissible]="true"
      alertClass="floating"
      (close)="dismissPendingAction()"
    >
      <p class="mb-0">
        {{ 'app.pending-action' | translate: { label: resolvePendingActionLabel(translate) } }}
      </p>
    </app-duke-alert>
  </div>
  <div *ngIf="showLocaleNotSupported()" class="localeNotification">
    <app-duke-alert
      type="danger"
      alertClass="floating"
      [dismissible]="true"
      data-test-locale-notification
      (close)="dismissLocaleNotSupported()"
    >
      <p class="mb-0">
        {{'app.requestedLocaleNotSupported' | translate}}
      </p>
    </app-duke-alert>
  </div>
  <ng-container>
    <!-- yet another angular quirk: Having ngIf on the router-outlet will fail route guards -->
    <router-outlet #routerOutlet="outlet" (activate)="onRouteActivated($event)"></router-outlet>
  </ng-container>
  <header *ngIf="showHeader" [class]="isUserAuthenticated() ? 'has-session order-1' : ''">
    <h1 class="mx-auto mb-0 logo-holder">
      <a
        [routerLink]="[getHomeLink()]"
        queryParamsHandling="merge"
        class="d-inline-block my-3"
        attr.aria-label="{{'app.header.logo.alt' | translate: { service_name: configuration.getProperties().serviceName} }}"
      >
        <app-logo class="img-fluid logo"></app-logo>
      </a>
    </h1>
  </header>
  <footer *ngIf="showFooter" class="order-3">
    <nav class="navbar text-muted pt-0">
      <ul class="navbar-nav mr-auto ml-0 small" *ngIf="showLocaleSwitcher">
        <li
          class="nav-item"
          ngbDropdown
          placement="bottom-left top-left"
          display="dynamic"
        >
          <a data-test-change-language-tool class="nav-link" style="cursor: pointer" ngbDropdownToggle id="navbarDropdown1" role="button" tabindex="0">
            <span class="icon fa fa-2x fa-fw fa-language align-middle mr-2"></span>
            <span class="align-middle" [innerHTML]="'app.footer.current-language' | translate"></span>
          </a>
          <div
            ngbDropdownMenu

            aria-labelledby="languageDropdown"
            class="position-absolute dropdown-menu"
          >
            <button
              *ngFor="let itm of supportedLanguages"
              [attr.data-test-change-language-trigger]="itm.value"
              ngbDropdownItem
              (click)="changeLanguage(itm.value)"
              [ngClass]="{ active: isSelectedLanguage(itm.value) }">
              {{itm.label}}
            </button>
          </div>
        </li>
      </ul>
      <span
        class="navbar-brand mr-0 ml-auto"
        [innerHTML]="'app.footer.copy'|translate"
      ></span>
    </nav>
  </footer>
  <div class="lds-mask" *ngIf="processing">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</article>
