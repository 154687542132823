/**
 * 10Duke Session API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiError } from '../model/apiError';
import { AuthenticationFlows } from '../model/authenticationFlows';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthenticationFlowService {

    protected basePath = 'http://localhost:8080/api/0.exp';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Gets authentication flows available in the system and for a user
     * Gets objects describing how the user, or users in general, must be authenticated. Each returned object describes a possible flow, and user must complete one of the returned flows. When returning user authentication flows, only challenges that the user can complete (e.g. TOTP can only be completed when TOTP is configured) and that the user has not yet completed are returned. When returning application default flows, they describe authentication mechanisms available in the system in general.
     * @param userName Login email for the user to authenticate. If an empty string is given and there is an authenticated user session, authentication flows for the session user are returned. If an empty string is given and there is no authenticated session, application default authentication flows are returned. If an underscore (\&quot;_\&quot;) is given, the application default authentication flows are always returned.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listUserAuthenticationFlows(userName: string, observe?: 'body', reportProgress?: boolean): Observable<AuthenticationFlows>;
    public listUserAuthenticationFlows(userName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthenticationFlows>>;
    public listUserAuthenticationFlows(userName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthenticationFlows>>;
    public listUserAuthenticationFlows(userName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userName === null || userName === undefined) {
            throw new Error('Required parameter userName was null or undefined when calling listUserAuthenticationFlows.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AuthenticationFlows>(`${this.basePath}/sessions/authenticationFlows/${encodeURIComponent(String(userName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
