<!--suppress HtmlFormInputWithoutLabel -->
<div [class]="controlWrapperClass()">
  <ng-template #formControl>
    <textarea
      *ngIf="type === 'textarea'"
      [id]="field"
      [name]="field"
      [formControlName]="field"
      class="form-control"
      [ngClass]="resolvedInputNGClass"
      [attr.autocomplete]="autocomplete !== undefined ? autocomplete : false"
      (focus)="onInputFocus()"
      [placeholder]="placeholder !== undefined ? placeholder : false"
      [readonly]="readonly !== undefined && readonly !== false"
      rows="4"
    ></textarea>
    <input
      *ngIf="type !== 'select' && type !== 'textarea'"
      [id]="field"
      [name]="field"
      [type]="type"
      [formControlName]="field"
      class="form-control"
      [ngClass]="resolvedInputNGClass"
      [autocomplete]="autocomplete !== undefined ? autocomplete : false"
      (focus)="onInputFocus()"
      [placeholder]="placeholder !== undefined ? placeholder : false"
      [readonly]="readonly !== undefined && readonly !== false"
    />
    <select
      *ngIf="type === 'select'"
      [id]="field"
      [name]="field"
      [formControlName]="field"
      class="form-control with-placeholder-label"
      [ngClass]="resolvedInputNGClass"
      (focus)="onInputFocus()"
    >
      <option *ngFor="let itm of selectOptions" [value]="itm.value">{{itm.label}}</option>
    </select>
  </ng-template>
  <ng-template #content><ng-content></ng-content></ng-template>
  <div [class]="'input-group' + (size ? ' input-group-' + size : '')" *ngIf="prepend || append">
    <div class="input-group-prepend" *ngIf="prepend">
      <ng-container [ngTemplateOutlet]="prepend"></ng-container>
    </div>
    <div class="input-group-input-wrapper">
      <ng-container [ngTemplateOutlet]="formControl"></ng-container>
      <label
        [for]="field"
        [class]="labelClass()"
      >{{label}}</label>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
    <div class="input-group-append" *ngIf="append">
      <ng-container [ngTemplateOutlet]="append"></ng-container>
    </div>
  </div>
  <ng-container *ngIf="!prepend && !append" [ngTemplateOutlet]="formControl"></ng-container>
  <ng-container *ngIf="!prepend && !append" [ngTemplateOutlet]="content"></ng-container>
  <label
    *ngIf="!prepend && !append"
    [for]="field"
    [class]="labelClass()"
  >{{label}}</label>
</div>
