import {Component, HostBinding, Input, OnChanges, OnInit} from '@angular/core';
import {inputFeedbackTransition} from '../animations';

@Component({
  selector: '[app-form-input-feedback]',
  templateUrl: './form-input-feedback.component.html',
  styleUrls: ['./form-input-feedback.component.scss'],
  animations: [
    inputFeedbackTransition,
  ],
})
export class FormInputFeedbackComponent implements OnInit, OnChanges {

  icon: string;
  hostClass: string;

  @Input() type: string;
  @Input() class: string;
  @Input() ngClass: any;
  @HostBinding('class') get whatever(): string {
    const retVal: Array<string> = [];
    if (this.hostClass) {
      retVal.push(this.hostClass);
    }
    if (this.class) {
      retVal.push(this.class);
    }
    if (this.ngClass) {
      for (const i in this.ngClass) {
        if (this.ngClass.hasOwnProperty(i) && this.ngClass[i]) {
          retVal.push(i);
        }
      }
    }
    return retVal.length ? retVal.join(' ') : undefined;
  }
  @HostBinding('@inputFeedbackTransition') trigger = '';

  constructor() { }
  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    if (this.type === 'invalid') {
      this.icon = 'icon mr-1 fa fa-fw fa-exclamation-circle';
      this.hostClass = 'feedback invalid-feedback text-right';
    } else if (this.type === 'valid') {
      this.icon = 'icon mr-1 fa fa-fw fa-check-circle';
      this.hostClass = 'feedback valid-feedback text-right';
    } else {
      this.icon = 'icon mr-1 fa fa-fw fa-info-circle';
      this.hostClass = 'feedback info-feedback small form-text text-muted text-right';
    }

  }


}
