import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from '../configuration-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent extends StandaloneComponent {
  constructor(
    route: ActivatedRoute,
    router: Router,
    hostElement: ElementRef,
    configuration: ConfigurationService
  ) {
    super(route, router, hostElement, configuration);
  }
}
