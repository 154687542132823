import branding from '../branding.json';
import uiConf from '../ui-conf.json';
import {DEFAULT_UI_CONFIGURATION, SsoUIConfiguration, SsoUIField, SsoUILocaleSwitcherLocale, SsoUIOutputType} from '../ssoui-configuration';
import {Inject, Injectable} from '@angular/core';
import {AuthenticationState} from './app-state.service';
import {BehaviorSubject, Observable} from 'rxjs';

const cloneDeep = require('lodash.clonedeep');
const lodash = require('lodash');

const merge = lodash.mergeWith;
/**
 * Customizes merging of arrays by concatenating arrays and removing duplicates
 * @param objValue Obj to merge
 * @param srcValue Obj to merge with
 * @return Merged array or undefined
 */
const mergeCustomizer = function(objValue, srcValue): any[]|undefined {
  if (lodash.isArray(objValue)) {
    const arr = objValue.concat(srcValue);
    return arr.filter((val, ind) => {
      return arr.indexOf(val) === ind;
    });
  }
};

/**
 * Ensures that inputted fields includes all required and only supported fields
 * @param fields The fields to ensure
 * @param required List of required field
 * @param supported List of supported fields
 * @returns The resolved fields
 */
function ensureValidSetOfFields(fields: SsoUIField[], required: string[], supported: string[]): SsoUIField[] {
  const retVal: SsoUIField[] = [];
  const f = cloneDeep(fields);
  // filter out unsupported
  f.filter((val, ind) => f.findIndex(val2 => val2.key === val.key) === ind)
    // check that the fields are supported
    .forEach((val) => {
      if (supported.indexOf(val.key) >= 0) {
        if (!val.required && required.indexOf(val.key) >= 0) {
          console.error('Configured field marked as not required, but it is required by the system: ' + val.key);
          val.required = true;
        }
        retVal.push(val);
      } else {
        console.error('Configured field not supported: %o', val.key);
      }
    })
  ;
  // add minimum set
  required.forEach((val) => {
    if (retVal.findIndex((value) => value.key === val) < 0) {
      console.error('Minimum field requirement missing from configuration: %o', val);
      retVal.push({ key: val, required: true });
    }
  });

  return retVal;
}

/**
 * Holder object for the field configuration
 */
const FIELD_CONFIG: any = {
  PROFILE_SUPPORTED_PERSONAL_FIELDS: [],
  PROFILE_REQUIRED_PERSONAL_FIELDS: [],
  PROFILE_SUPPORTED_CONTACT_FIELDS: [],
  PROFILE_REQUIRED_CONTACT_FIELDS: [],
  PROFILE_SUPPORTED_LOGIN_FIELDS: [],
  PROFILE_REQUIRED_LOGIN_FIELDS: [],
  REGISTRATION_SUPPORTED_FIELDS: [],
  REGISTRATION_REQUIRED_FIELDS: [],
};
/**
 * Singleton api description object
 */
let openAPI: any;

/**
 * Resolves a schema reference as object from the openAPI
 * @param ref Reference string
 * @returns Resolved object
 */
function resolveReference(ref: string): any {
  const pathToObj = ref.split('/');
  let refObj: any = openAPI;
  for (let j = 1; j < pathToObj.length; j += 1) {
    refObj = refObj[pathToObj[j]];
  }
  return resolveObject(refObj);
}

/**
 * Resolves the schema description as object according to the description
 * @param obj The Schema to resolve
 * @returns Resolved object
 */
function resolveObject(obj: any): any {
  let object = cloneDeep(obj);
  if (object.$ref) {
    const tmp = object.$ref;
    delete object.$ref;
    object = resolveReference(tmp);
  } else {
    if (object.allOf) {
      const tmp = cloneDeep(object.allOf);
      delete object.allOf;
      for (let i = 0; i < tmp.length; i += 1) {
        object = merge(object, resolveObject(tmp[i]), mergeCustomizer);
      }
    }
    if (object.type === 'object') {
      if (!object.properties) {
        object.properties = {};
      }
      const props = Object.keys(object.properties);
      object.supported = [];
      for (let i = 0; i < props.length; i += 1) {
        object.properties[props[i]] = resolveObject(object.properties[props[i]]);
        object.supported.push(props[i]);
      }
    }
    if (object.type === 'object' && object.supported) {
      object.supported = object.supported.reduce(function(result, value) {
        if (object.properties[value].type === 'object') {
          for (let i = 0; i < object.properties[value].supported.length; i += 1) {
            result.push(value + '-' + object.properties[value].supported[i]);
          }
        } else {
          result.push(value);
        }
        return result;
      }, []);
    }
    if (object.type === 'object' && object.required) {
      object.required = object.required.reduce(function(result, value) {
        if (object.properties[value].type === 'object') {
          for (let i = 0; i < object.properties[value].required.length; i += 1) {
            result.push(value + '-' + object.properties[value].required[i]);
          }
        } else {
          result.push(value);
        }
        return result;
      }, []);
    }
  }
  return object;
}


/**
 * Filter function for identifying personal fields from all profile fields
 * @param val List value
 * @returns true for personal fields, false for others
 */
function isPersonalField(val: string): boolean {
  return ['firstName', 'lastName', 'nickname', 'preferredUsername', 'professionalTitle'].indexOf(val) >= 0;
}

/**
 * Filter function for identifying contact fields from all profile fields
 * @param val List value
 * @returns true for contact fields, false for others
 */
function isContactField(val: string): boolean {

  return val.startsWith('address-') || val === 'phoneNumber';
}

/**
 * Filter function for identifying login fields from all profile fields
 * @param val List value
 * @returns true for login fields, false for others
 */
function isLoginField(val: string): boolean {
  return ['email', 'password', 'totp'].indexOf(val) >= 0;
}

/**
 * Resolves authentcication methods recursively from AuthenticationFlows
 * @param obj The flows, single flow or challenge list
 * @returns Array of authentication methods names
 */
const resolveAuthenticationMethods = function(obj: any): string[] {
  let result: string[] = [];
  if (lodash.isArray(obj)) {
    for (let i = 0; i < obj.length; i += 1) {
      result = result.concat(resolveAuthenticationMethods(obj[i]));
    }
  } else {
    if (obj.authenticationMethod) {
      result.push(obj.authenticationMethod);
    }
    if (obj.challenges) {
      result = result.concat(resolveAuthenticationMethods(obj.challenges));
    }
  }
  return result;
};

function addTrailingZerosToVersion(pa: string[]) {
  const retVal = ([] as string[]).concat(pa);
  if (retVal.length < 1) {
    retVal.push('0');
  }
  if (retVal.length < 2) {
    retVal.push('0');
  }
  if (retVal.length < 3) {
    retVal.push('0');
  }
  return retVal;
}
/**
 * Semantic version comparison
 */
function compareVersions(a: string, b: string) {
  const pa = addTrailingZerosToVersion(a.split('.'));
  const pb = addTrailingZerosToVersion(b.split('.'));
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i]);
    const nb = Number(pb[i]);
    if (na > nb) {
      return 1;
    }
    if (nb > na) {
      return -1;
    }
    if (!isNaN(na) && isNaN(nb)) {
      return 1;
    }
    if (isNaN(na) && !isNaN(nb)) {
      return -1;
    }
  }
  return 0;
}

// Please note that even with these typed variables, there is absolutely no guarantee that the conf is good to go.
// you can write pretty much what ever you wish into the json, as long as it's valid json it'll fly.
// Until the sh*t hits the fan after deployment.
const config: SsoUIConfiguration =  cloneDeep(uiConf) as SsoUIConfiguration;


const inputIsEmpty = Object.keys(config).length === 0;
if (!inputIsEmpty) {

  const versionComparisonResult = compareVersions(
    DEFAULT_UI_CONFIGURATION.version as string,
    config.version || ''
  );
  if (versionComparisonResult > 0) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        'UIConfiguration version is outdated (version: %o). Please migrate the configuration to latest (version %o).',
        config.version,
        DEFAULT_UI_CONFIGURATION.version
      );
    }
    if (compareVersions('0.1', config.version || '') > 0) {
      // Transform v<=0.0.1 to v0.1.0
      if (!config.functionality) {
        config.functionality = {};
      }
      if (config.functionality['locale-switcher']) {
        const clone = {...config.functionality['locale-switcher']};
        if (clone.show !== undefined) {
          clone['show-switcher'] = clone.show;
          delete clone.show;
        }
        config.functionality.localization = clone;
        delete config.functionality['locale-switcher'];
      }
      if (!config.functionality.localization) {
        config.functionality.localization = {};
      }
      if (!config.functionality.localization.locales) {
        // no locales specified, so populate with old defaults.
        config.functionality.localization.locales = [...DEFAULT_UI_CONFIGURATION.functionality.localization.locales.filter(
          (l) => l.value !== 'cs' && l.value !== 'sk'
        )];
        config.functionality.localization.locales.push({'value': 'ja', 'label': '日本語'});
      }
      if (config.functionality['show-email-validation-code-input'] === undefined) {
        config.functionality['show-email-validation-code-input'] = true;
      }
      config.version = '0.1.0';
    }
    if (compareVersions('0.1.1', config.version || '') > 0) {
      // ensure that the required conf structure exists
      if (!config.functionality) {
        config.functionality = {};
      }
      if (!config.functionality.localization) {
        config.functionality.localization = {};
      }
      if (!config.functionality.localization.locales) {
        // filter out the newly added localization
        config.functionality.localization.locales =
          DEFAULT_UI_CONFIGURATION?.functionality?.localization?.locales?.filter(
            (l) => l.value !== 'cs' && l.value !== 'sk'
          );
      }
      config.version = '0.1.1';

    }
  } else if (versionComparisonResult < 0) {
    throw new Error(
      'Configuration version (' +
      config.version +
      ') not supported. Latest version is (' +
      DEFAULT_UI_CONFIGURATION.version +
      ')'
    );
  }
}





// assign conf into variables for easier usage, for missing conf input, use defaults.
const functionality = cloneDeep(config.functionality || DEFAULT_UI_CONFIGURATION.functionality);
const locale = cloneDeep(functionality.localization || DEFAULT_UI_CONFIGURATION.functionality.localization);
const loginToContinueNotification = cloneDeep(
  functionality['login-to-continue-notification'] || DEFAULT_UI_CONFIGURATION.functionality['login-to-continue-notification'])
;
const pendingActionNotification = cloneDeep(
  functionality['pending-action-notification'] || DEFAULT_UI_CONFIGURATION.functionality['pending-action-notification'])
;
const profile = cloneDeep(functionality.profile || DEFAULT_UI_CONFIGURATION.functionality.profile);
const profile_personal = cloneDeep(profile.personal || DEFAULT_UI_CONFIGURATION.functionality.profile.personal);
const profile_contact = cloneDeep(profile.contact || DEFAULT_UI_CONFIGURATION.functionality.profile.contact);
const profile_login = cloneDeep(profile.login || DEFAULT_UI_CONFIGURATION.functionality.profile.login);
const registration = cloneDeep(functionality.registration || DEFAULT_UI_CONFIGURATION.functionality.registration);

/**
 * Describes the configuration data model. The members with type SsoUIField[] are populated by configured values and APi requirements:
 * Missing required fields are added, unsupported ones removed and required fields configured as optional are made required, an error is
 * shown in console for all of these.
 *
 * Other fields get their values from the provided ui-conf.json or DEFAULT_UI_CONFIGURATION, unless stated otherwise.
 */
export interface ConfigurationModel {
  /**
   * Name of the service/app eg. 10Duke Identity.
   * Resolve value fallback chain includes branding.json as it's more specific than our DEFAULT_UI_CONFIGURATION.
   * (config['service-name'] || branding['service-name'] || DEFAULT_UI_CONFIGURATION['service-name'])
   */
  serviceName: string;

  /**
   * Toggles the visibility of header.
   */
  showHeader: boolean;

  /**
   * Toggles the visibility of sessionPanel.
   */
  showSessionPanel: boolean;

  /**
   * Toggles the visibility of footer.
   */
  showFooter: boolean;

  /**
   * Toggles the visibility of login to continue notification when login is a pre-step for a navigation action.
   */
  showLoginToContinue: boolean;

  /**
   * Toggles the visibility of penning action notification when login is a pre-step for an action.
   */
  showPendingAction: boolean;

  /**
   * Toggles the availability of an input field for email validation code in UI and related instructional copy.
   * Makes sense only if the sent email includes the code for copy paste.
   */
  showEmailValidationCodeInput: boolean;

  /**
   * Toggles the availability of an input field for reset password code in UI and related instructional copy.
   * Makes sense only if the sent email includes the code for copy paste.
   */
  showResetPasswordCodeInput: boolean;

  /**
   * Toggles the availability of password input fields for reset password when no valid code is found.
   */
  showResetPasswordFieldsWithoutCode: boolean;

  /**
   * List of locales to show for the locale switching tool.
   * Providing an empty list in ui-config.json is ignored, as it makes nop sense to show an empty dropdown.
   */
  supportedLocales: SsoUILocaleSwitcherLocale[];

  /**
   * Defines the locale to use when none is defined
   */
  defaultLocale: string;

  /**
   * Toggles the availability of the locale switching tool.
   */
  showLocaleSwitcher: boolean;

  /**
   * Fields to display under personal-category/tab.
   * `[...profilePersonalFields, ...profileContactFields, ...profileLoginFields]` determines the available user object fields
   * for the whole app. If a field is not found from this set, it is as if it does not exist in data.
   */
  profilePersonalFields: SsoUIField[];


  /**
   * Fields to display under contact-category/tab
   * `[...profilePersonalFields, ...profileContactFields, ...profileLoginFields]` determines the available user object fields
   * for the whole app. If a field is not found from this set, it is as if it does not exist in data.
   */
  profileContactFields: SsoUIField[];

  /**
   * Fields to display under login-category/tab. Password is added, if it's supported for registration. Totp is added if it's supported
   * by login API.
   * `[...profilePersonalFields, ...profileContactFields, ...profileLoginFields]` determines the available user object fields
   * for the whole app. If a field is not found from this set, it is as if it does not exist in data.
   */
  profileLoginFields: SsoUIField[];

  /**
   * Toggles the automatic sending of email validation message after successful registration.
   */
  registrationSendValidationEmail: boolean;

  /**
   * Fields to display under registration
   */
  registrationFields: SsoUIField[];

  // tslint:disable:max-line-length
  /**
   * Regex used for validating passwords. If none is required by the API, then default pattern `.*` is used
   * eg. Require length of at least 8 characters and at least on of each: lower case letter, upper case letter, digit, special character
   * `/^(?=(?:[^a-zà-öø-þ]*[a-zà-öø-þ]){1,}[^a-zà-öø-þ]*)(?=(?:[^A-ZÀ-ÖØ-Þ]*[A-ZÀ-ÖØ-Þ]){1,}[^A-ZÀ-ÖØ-Þ]*)(?=(?:[^0-9]*[0-9]){1,}[^0-9]*)(?=(?:[a-zà-öø-þA-ZÀ-ÖØ-Þ0-9]*[^a-zà-öø-þA-ZÀ-ÖØ-Þ0-9]){1,}[a-zà-öø-þA-ZÀ-ÖØ-Þ0-9]*).{8,}$/`
   */
  passwordValidation: RegExp;
  // tslint:enable:max-line-length
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {

  public isReady = false;
  private confIsReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isReady);

  private properties: ConfigurationModel;

  constructor(
    @Inject('FetchOpenAPIDescription') private fetchOpenAPIDescription
  ) {}

  /**
   * Returns the actual configuration, or an empty object to avoid having to repeat sanity checks for every use case.
   */
  public getProperties(): ConfigurationModel|any {
    return this.isReady ? this.properties : {};
  }


  /**
   *
   */
  public whenReady(): Observable<boolean> {
    return this.confIsReadySubject;
  }

  /**
   * Initializes the configuration using provided ui-config.json, DEFAULT_UI_CONFIGURATION and openAPI description
   * @param resolveAuthState Wrapper function that allows this conf service to call AppStateService's fetchAuthenticationFlows without
   * injection that would cause circular dependency
   */
  public async initializeConfiguration(resolveAuthState: (cb: (val: AuthenticationState) => void) => void): Promise<void> {
    if (this.isReady) {
      return;
    }
    // cant be of correct type until completely filled with all properties.
    const propertiesHolder: any = {
      serviceName: '' + (config['service-name'] || branding['service-name'] || DEFAULT_UI_CONFIGURATION['service-name']),
      showHeader: (config.output !== undefined ? config.output : DEFAULT_UI_CONFIGURATION.output) !== SsoUIOutputType.CONTENT_ONLY,
      showFooter: (config.output !== undefined ? config.output : DEFAULT_UI_CONFIGURATION.output) !== SsoUIOutputType.CONTENT_ONLY,
      showSessionPanel: (config.output !== undefined ? config.output : DEFAULT_UI_CONFIGURATION.output) !== SsoUIOutputType.CONTENT_ONLY,
      showLoginToContinue: true === (
        loginToContinueNotification.show !== undefined ?
          loginToContinueNotification.show :
          DEFAULT_UI_CONFIGURATION.functionality['login-to-continue-notification'].show
      ),
      showPendingAction: true === (
        pendingActionNotification.show !== undefined ?
          pendingActionNotification.show :
          DEFAULT_UI_CONFIGURATION.functionality['pending-action-notification'].show
      ),
      showEmailValidationCodeInput: true === (
        functionality['show-email-validation-code-input'] !== undefined ?
          functionality['show-email-validation-code-input'] :
          DEFAULT_UI_CONFIGURATION.functionality['show-email-validation-code-input']
      ),
      showResetPasswordCodeInput: true === (
        functionality['show-reset-password-code-input'] !== undefined ?
          functionality['show-reset-password-code-input'] :
          DEFAULT_UI_CONFIGURATION.functionality['show-reset-password-code-input']
      ),
      showResetPasswordFieldsWithoutCode: true === (
        functionality['show-reset-password-fields-without-code'] !== undefined ?
          functionality['show-reset-password-fields-without-code'] :
          DEFAULT_UI_CONFIGURATION.functionality['show-reset-password-fields-without-code']
      ),
      supportedLocales: cloneDeep(locale.locales && locale.locales.length ?
        locale.locales :
        DEFAULT_UI_CONFIGURATION.functionality.localization.locales
      ),
      defaultLocale: locale.default ? locale.default : DEFAULT_UI_CONFIGURATION.functionality.localization.default,
      showLocaleSwitcher: true === (
        locale['show-switcher'] !== undefined ? locale['show-switcher'] : DEFAULT_UI_CONFIGURATION.functionality.localization['show-switcher']
      ),
      registrationSendValidationEmail: true === (registration['send-validation-email'] !== undefined ?
        registration['send-validation-email'] :
        DEFAULT_UI_CONFIGURATION.functionality.registration['send-validation-email']),
      passwordValidation: /.*/,
      allowedOriginsForNextParam:
        functionality['allowed-origins-for-next-param'] !== undefined ?
          functionality['allowed-origins-for-next-param'] :
          DEFAULT_UI_CONFIGURATION.functionality['allowed-origins-for-next-param'],
    };
    openAPI = await this.fetchOpenAPIDescription();
    // Access required schemas
    if (!openAPI.components || !openAPI.components.schemas) {
      throw new Error('Schemas not found from Api description');
    }
    if (!openAPI.components.schemas.User || !openAPI.components.schemas.UserForRegisterUser) {
      throw new Error('User schema not found from Api description');
    }

    const User = resolveObject(openAPI.components.schemas.User);
    const UserForRegisterUser = resolveObject(openAPI.components.schemas.UserForRegisterUser);
    // create lists of required/supported fields.
    FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS = cloneDeep(
      UserForRegisterUser.required ? UserForRegisterUser.required : [])
    ;
    // add acceptTsAndCs and pwd confirmation field as required if pwd is required
    if (FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS.indexOf('password') >= 0) {
      FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS = FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS
        .concat(['confirmPassword', 'acceptTsAndCs']);
    } else {
      FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS.push('acceptTsAndCs');
    }
    FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS = cloneDeep(
      UserForRegisterUser.supported ? UserForRegisterUser.supported : []);
    // add acceptTsAndCs and pwd confirmation field  as supported if pwd is supported
    if (FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS.indexOf('password') >= 0) {
      FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS = FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS
        .concat(['confirmPassword', 'acceptTsAndCs']);
      if (UserForRegisterUser.properties.password.pattern) {
        propertiesHolder.passwordValidation = new RegExp(UserForRegisterUser.properties.password.pattern);
      }
    } else {
      FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS.push('acceptTsAndCs');
    }

    // Get auth flows and check if totp is supported
    return new Promise<void>((resolve) => {
      resolveAuthState(
        (val: AuthenticationState) => {
          const availableFlows = val.getAuthenticationFlows();
          const userRequired = cloneDeep(User.required ? User.required : []);
          const userSupported = cloneDeep(User.supported ? User.supported : []);
          const availableAuthenticationMethods = resolveAuthenticationMethods(availableFlows);
          if (availableAuthenticationMethods.indexOf('totp') >= 0) {
            userSupported.push('totp');
          }
          // add password into supoprted user fields, if it's supported in registartion to enable pwd change
          if (FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS.indexOf('password')) {
            userSupported.push('password');
          }
          // Create the categorized field lists from profile fields
          FIELD_CONFIG.PROFILE_REQUIRED_PERSONAL_FIELDS = userRequired.filter(isPersonalField);
          FIELD_CONFIG.PROFILE_SUPPORTED_PERSONAL_FIELDS = userSupported.filter(isPersonalField);

          FIELD_CONFIG.PROFILE_REQUIRED_CONTACT_FIELDS = userRequired.filter(isContactField);
          FIELD_CONFIG.PROFILE_SUPPORTED_CONTACT_FIELDS = userSupported.filter(isContactField);

          FIELD_CONFIG.PROFILE_REQUIRED_LOGIN_FIELDS = userRequired.filter(isLoginField);
          FIELD_CONFIG.PROFILE_SUPPORTED_LOGIN_FIELDS = userSupported.filter(isLoginField);
          // Create the actual field lists to use, ensurong that they include all required and only supported ones.
          propertiesHolder.profilePersonalFields =
            cloneDeep(ensureValidSetOfFields(
              profile_personal.fields || DEFAULT_UI_CONFIGURATION.functionality.profile.personal.fields,
              FIELD_CONFIG.PROFILE_REQUIRED_PERSONAL_FIELDS,
              FIELD_CONFIG.PROFILE_SUPPORTED_PERSONAL_FIELDS))
          ;
          propertiesHolder.profileContactFields =
            cloneDeep(ensureValidSetOfFields(
              profile_contact.fields || DEFAULT_UI_CONFIGURATION.functionality.profile.contact.fields,
              FIELD_CONFIG.PROFILE_REQUIRED_CONTACT_FIELDS,
              FIELD_CONFIG.PROFILE_SUPPORTED_CONTACT_FIELDS))
          ;
          propertiesHolder.profileLoginFields =
            cloneDeep(ensureValidSetOfFields(
              profile_login.fields || DEFAULT_UI_CONFIGURATION.functionality.profile.login.fields,
              FIELD_CONFIG.PROFILE_REQUIRED_LOGIN_FIELDS,
              FIELD_CONFIG.PROFILE_SUPPORTED_LOGIN_FIELDS))
          ;
          propertiesHolder.registrationFields =
            cloneDeep(ensureValidSetOfFields(
              registration.fields || DEFAULT_UI_CONFIGURATION.functionality.registration.fields,
              FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS,
              FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS))
          ;
          this.properties = propertiesHolder as ConfigurationModel;
          this.isReady = true;
          this.confIsReadySubject.next(this.isReady);
          resolve();
        });
      })
    ;
  }
}
