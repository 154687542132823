import {
  Component, Optional, Host, ElementRef, SkipSelf, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output,
  OnDestroy
} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AppConstants} from '../AppConstants';

@Component({
  selector: '[app-form-input]',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormInputComponent implements OnInit, OnChanges, OnDestroy {

  public static WRAPPER_CLASS = 'form-group';
  public static LABEL_CLASS = 'placeholder-label';
  public static CONTROL_WRAPPER_CLASS = '';

  @Input() class: string;
  @Input() field: string;
  @Input() label: string;
  @Input() type: string;
  @Input() size: string;
  @Input() readonly: boolean;
  @Input() autocomplete: string;
  @Input() placeholder: string;
  @Input() isValid: boolean;
  @Input() isInvalid: boolean;
  @Input() selectOptions: any[];
  @Input() append: ElementRef;
  @Input() prepend: ElementRef;
  @Output() focus = new EventEmitter();

  @HostBinding('class') hostClass;
  @HostBinding('attr.autocomplete') hostAutocomplete;
  @HostBinding('attr.field') hostField;
  @HostBinding('attr.label') hostLabel;
  @HostBinding('attr.type') hostType;
  @HostBinding('attr.size') hostSize;
  @HostBinding('attr.placeholder') hostPlaceholder;

  resolvedInputNGClass: any;

  private valueChangeSubscription: Subscription;

  constructor(
    private el: ElementRef,
    private cc: ControlContainer
  ) {}

  ngOnInit() {
    if (this.cc && this.cc.control && this.cc.control instanceof FormGroup) {
      this.valueChangeSubscription = this.cc.control.controls[this.field].valueChanges.subscribe((val) => {
        this.resolvedInputNGClass = {
          'is-valid': this.isValid,
          'is-invalid': this.isInvalid,
          'has-value': !!val && val.match(AppConstants.NOT_NULL_PATTERN),
          'form-control-lg': this.size === 'lg',
          'form-control-sm': this.size === 'sm',
        };
      });
    } else {
      throw new Error('Only FormGroup wrapped controls are supported');
    }
  }

  ngOnDestroy() {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
      this.valueChangeSubscription = undefined;
    }
  }

  ngOnChanges() {

    if (this.type === undefined || (this.type === 'select' && this.readonly)) {
      this.type = 'text';
    }
    if (this.placeholder === undefined) {
      this.placeholder = '';
    }
    if (this.autocomplete === undefined) {
      this.autocomplete = '';
    }
    if (this.class === undefined) {
      this.hostClass = FormInputComponent.WRAPPER_CLASS;
    } else {
      this.hostClass = FormInputComponent.WRAPPER_CLASS + ' ' + this.class;
    }

    this.resolvedInputNGClass = {
      'is-valid': this.isValid,
      'is-invalid': this.isInvalid,
      'has-value': (this.cc && this.cc.control && this.cc.control instanceof FormGroup ?
        !!this.cc.control.controls[this.field].value && this.cc.control.controls[this.field].value.match(AppConstants.NOT_NULL_PATTERN) :
        false),
      'form-control-lg': this.size === 'lg',
      'form-control-sm': this.size === 'sm',
    };

    this.hostAutocomplete = null;
    this.hostField = null;
    this.hostLabel = null;
    this.hostType = null;
    this.hostSize = null;
    this.hostPlaceholder = null;
  }

  // noinspection JSMethodCanBeStatic
  wrapperClass(): string {
    return FormInputComponent.WRAPPER_CLASS;
  }
  // noinspection JSMethodCanBeStatic
  labelClass(): string {
      return FormInputComponent.LABEL_CLASS;
  }
  // noinspection JSMethodCanBeStatic
  controlWrapperClass(): string {
    return FormInputComponent.CONTROL_WRAPPER_CLASS;
  }

  onInputFocus() {
    this.focus.emit();
  }
}
