import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'src/util/logger';
import {AppConstants} from './AppConstants';
import { ParamsService } from './params.service';
import {APP_BASE_HREF, DOCUMENT} from '@angular/common';
import {User} from '../gen';
import {LocaleService} from './locale.service';
import {routeTransition} from './animations';
import {StandaloneComponent} from './standalone.component';
import {ConfigurationService} from './configuration-service';
import LocationUtil from '../util/locationUtil';
import {ProcessingIndicatorService} from './processing-indicator.service';
import {AppStateService} from './app-state.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {DEFAULT_UI_CONFIGURATION} from '../ssoui-configuration';
const cloneDeep = require('lodash.clonedeep');

// Could not figure out how to use the variables with this setup, so I added the logic here.
function buildWindowTitle(genericpart: string, componentpart?: string): string {
    return componentpart ? `${componentpart} | ${genericpart}` : genericpart;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ routeTransition ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewChecked {
  sessionPanelOpen = false;
  /** Processing state (sued for eg. redirecting out of this application) */
  processing: boolean;
  profile: User;

  supportedLanguages: any[];
  showLocaleSwitcher: boolean;
  showHeader: boolean;
  showFooter: boolean;
  showSessionPanel: boolean;

  private localeSupportDismissed = false;
  private loginBeforeNextDismissed = false;
  private pendingActionDismissed = false;
  private activeStandaloneComponent: StandaloneComponent;
  private delayNotifications = true;
  confReady = false;
  appStateReady = false;

  public static resolveUserName(user: User, translate: TranslateService, configuration: any): string|any {
    let retVal: string|any;
    if (user) {
      if (user.preferredUsername &&
        configuration.getProperties().profilePersonalFields.findIndex((val) => val.key === 'preferredUsername') >= 0) {
        retVal = user.preferredUsername;
      } else if (user.firstName && configuration.getProperties().profilePersonalFields.findIndex((val) => val.key === 'firstName') >= 0) {
        retVal = user.firstName;
        if (user.lastName && configuration.getProperties().profilePersonalFields.findIndex((val) => val.key === 'lastName') >= 0) {
          retVal += ' ' + user.lastName;
        }
      } else if (user.lastName && configuration.getProperties().profilePersonalFields.findIndex((val) => val.key === 'lastName') >= 0) {
        retVal = user.lastName;
      } else if (user.nickname && configuration.getProperties().profilePersonalFields.findIndex((val) => val.key === 'nickname') >= 0) {
        retVal = user.nickname;
      } else {
        retVal = translate.instant(_('app.user-name-label.unknown'));
      }
    } else {
      retVal = translate.instant(_('app.user-name-label.not-signed-in'));
    }
    //
    return retVal;
  }
  constructor(
    private changeRef: ChangeDetectorRef,
    private appStateService: AppStateService,
    private processingService: ProcessingIndicatorService,
    private logger: Logger,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
    private meta: Meta,
    private paramsService: ParamsService,
    private configuration: ConfigurationService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private localeService: LocaleService) {
    this.processingService.subscribe((val: boolean) => {
      this.processing = val;
    });
    if (window && window.self !== window.parent) {
      this._document.body.classList.add('embedded');
    }
    // these calls have to include "this." or else the resource extractor will fail to identify
    this.translate.onLangChange.subscribe(next => {
      this._document.documentElement.lang = next.lang;
      this.logger.debug('Language: %s', next.lang);

      if (this.activeStandaloneComponent) {
        this.activeStandaloneComponent.resolveWindowTitlePart().subscribe(part => {
          this.translate.get('app.window-title', {service_name: this.configuration.getProperties().serviceName}).subscribe(res => {
            titleService.setTitle(buildWindowTitle(res, part));
          });
        });
      } else {
        this.translate.get('app.window-title', {service_name: this.configuration.getProperties().serviceName}).subscribe(res => {
          titleService.setTitle(buildWindowTitle(res));
        });
      }
      this.translate.get('app.window-description', {service_name: this.configuration.getProperties().serviceName}).subscribe(res => {
        meta.addTag({ name: 'Description', content: res });
      });
    });
  }
  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }
  ngOnInit() {
    this.configuration.initializeConfiguration((cb) =>
      this.appStateService.fetchAuthenticationState(AppStateService.USERNAME_FOR_FLOW_CONFIGURATION, cb)).then(() => {

      this.confReady = this.configuration.isReady;
      this.supportedLanguages = cloneDeep(this.configuration.getProperties().supportedLocales);
      const def = this.configuration.getProperties().defaultLocale;
      if (!!this.supportedLanguages.find((f) => f.value === def)) {
        // ignore unsupported default
        this.translate.setDefaultLang(def);
      } else if (!!this.supportedLanguages.find((f) => f.value === DEFAULT_UI_CONFIGURATION.functionality.localization.default)) {
        // ignore unsupported default fallback
        this.translate.setDefaultLang(DEFAULT_UI_CONFIGURATION.functionality.localization.default);
      }
      this.showLocaleSwitcher = this.configuration.getProperties().showLocaleSwitcher === true;
      this.showHeader = this.configuration.getProperties().showHeader !== false;
      if (!this.showHeader) {
        this._document.body.classList.add('no-header');
      }
      this.showFooter = this.configuration.getProperties().showFooter !== false;
      if (!this.showFooter) {
        this._document.body.classList.add('no-footer');
      }
      this.showSessionPanel = this.configuration.getProperties().showHeader !== false;
      // these calls have to include "this." or else the resource extractor will fail to identify
      this.translate.get('app.window-title', {service_name: this.configuration.getProperties().serviceName}).subscribe(res => {
        this.titleService.setTitle(buildWindowTitle(res));
      });
      this.translate.get('app.window-description', {service_name: this.configuration.getProperties().serviceName}).subscribe(res => {
        this.meta.addTag({ name: 'Description', content: res });
      });

      if (this.translate.currentLang && this.localeService.getIsAvailable()) {
        this._document.documentElement.lang = this.translate.currentLang;
      } else if (this.translate.defaultLang) {
        this._document.documentElement.lang = this.translate.defaultLang;
      } else {
        throw new Error('AppComponent.ngOnInit: no supported languages found to set as document language.');
      }
      this.appStateService.init(() => {
        // this.logger.info('AppComponent.ngOnInit appStateService.init cb %o', this.appStateService);
        this.appStateReady = true;
      });
    }).catch((e) => {
      throw new Error(e);
    });
    if (!this.disableAnimations()) {
      setTimeout(() => {
        this.delayNotifications = false;
      }, 600);
    } else {
      this.delayNotifications = false;
    }
  }
  onRouteActivated(c: StandaloneComponent) {
    this.activeStandaloneComponent = c;
    c.resolveWindowTitlePart().subscribe(part => {
      this.translate.get('app.window-title',
        {service_name: this.configuration.getProperties().serviceName}
      ).subscribe(res => {
        this.titleService.setTitle(buildWindowTitle(res, part));
      });

    });
  }
  resolveNextLabel(translate: TranslateService): string {
    let retVal = '';
    const next: string = ParamsService.getParamSync(AppConstants.QP_NEXT);
    if (next && next.match(/^(\/[a-z]{2}(-[a-z]{2})?)?\/validate\/.*/)) {
      retVal = translate.instant(_('app.next-label.validate'));
    } else {
      retVal = translate.instant(_('app.next-label.other'));
    }
    return retVal;
  }
  resolvePendingActionLabel(translate: TranslateService): string {
    let retVal = '';
    const require: string = ParamsService.getParamSync(AppConstants.QP_REQUIRE_CHALLENGE);
    if (require && require === AppConstants.AC_AM_INVITATION_ACCEPTED) {
      retVal = translate.instant(_('app.pending-action-label.invitationAccepted'));
    } else {
      retVal = translate.instant(_('app.pending-action-label.other'));
    }
    return retVal;
  }
  openSessionPanel() {
    if (!this.sessionPanelOpen) {
      setTimeout(() => {
        this.sessionPanelOpen = true;
      }, 1);
    }
  }
  prepareRouteTransition(o: RouterOutlet): any {
    let retVal: any = 'APP_INIT';
    if (this.isInitialized()) {
      if (o && o.isActivated && o.activatedRouteData && o.activatedRouteData['animation']) {
        retVal = o.activatedRouteData['animation'];
        if (retVal.indexOf(':') >= 0) {
          // Configured animation value contains dynamic data that needs to be injected from params
          const parts = retVal.split(':');
          // multiple params are separated by ,
          const params = parts.length > 1 ? parts[1].split(',') : [];
          // get the current values of the params, we don't care if they come from params or queryparams
          retVal = parts[0] + ':' + params.map((par) => (
            o.activatedRoute.snapshot.queryParams[par] || o.activatedRoute.snapshot.params[par]
          )).join(',');
        }
      } else if (0 && o.isActivated && o.activatedRoute) {
        retVal = o.activatedRoute;
      }
    }
    //
    return retVal;
  }
  showLoginBeforeNext(): boolean {
    if (!this.delayNotifications &&
      this.configuration.getProperties().showLoginToContinue &&
      !this.showLocaleNotSupported() &&
      !this.showPendingActionNotification() &&
      !this.loginBeforeNextDismissed &&
      (this.router.url.includes(AppConstants.PATH_LOGIN) ||
        this.router.url.includes(AppConstants.PATH_REGISTER))) {
      let prmNext = ParamsService.getParamSync(AppConstants.QP_NEXT);
      if (!!prmNext && !LocationUtil.isValidNext(prmNext, this.configuration.getProperties().allowedOriginsForNextParam)) {
        prmNext = null;
      }
      return prmNext !== null;
    }
    return false;
  }
  showPendingActionNotification(): boolean {
    let retVal;
    if (this.delayNotifications) {
      retVal = false;
    } else {
      if (this.pendingActionDismissed || !this.configuration.getProperties().showPendingAction || this.showLocaleNotSupported()) {
        retVal = false;
      } else {
        const require: string = ParamsService.getParamSync(AppConstants.QP_REQUIRE_CHALLENGE);
        if (require && require === AppConstants.AC_AM_INVITATION_ACCEPTED) {
          retVal = true;
        } else {
          retVal = false;
        }
      }
    }
    return retVal;
  }
  dismissLoginBeforeNext() {
    this.loginBeforeNextDismissed = true;
  }
  dismissPendingAction() {
    this.pendingActionDismissed = true;
  }
  showLocaleNotSupported(): boolean {
    return !this.delayNotifications && !this.localeService.getIsAvailable() && !this.localeSupportDismissed;
  }
  dismissLocaleNotSupported() {
    this.localeSupportDismissed = true;
  }
  resolveLoggedInUserName(): string|any {
    return AppComponent.resolveUserName(
      this.appStateService.getAppState().getAuthenticationState().getProfile(), this.translate, this.configuration);
  }
  getProfile(): User|undefined {
    return this.appStateService.getAppState().getAuthenticationState().getProfile();
  }
  signOut() {
    this.router.navigate([AppConstants.PATH_LOGOUT], {
      relativeTo: this.activatedRoute,
    });
  }
  changeLanguage(lang: string) {
    const current = this.translate.currentLang;
    const domain = LocationUtil.getOwnDomainURL();
    const url = window.location.href;
    let newUrl;
    // check if current language is defined in th url
    if (current && url.indexOf(domain + '/' + current + '/') === 0) {
      // replace lang, domain included to ensure that only the correct part of the url is changed, even if locale exists elsewhere as well
      newUrl = url.replace(domain + '/' + current + '/', domain + '/' + lang + '/');
    } else {
      // add lang, domain included to ensure that only the correct part of the url is changed, even if locale exists elsewhere as well
      newUrl = url.replace(domain, domain + '/' + lang);
    }
    window.location.href = newUrl;
  }
  isSelectedLanguage(lang: string): boolean {
    let retVal = false;
    const cur = this.translate.currentLang || this.translate.defaultLang;
    const exact = this.supportedLanguages.find((f) => f.value === cur);
    if (cur === lang) {
      retVal = true;
    } else if (!exact && lang.indexOf('-') > 0 && cur === lang.split('-')[0]) {
      retVal = true;
    } else if (!exact && cur.indexOf('-') > 0 && lang === cur.split('-')[0]) {
      retVal = true;
    }
    return retVal;
  }

  getHomeLink(): string {
    let retVal: string;
    if (this.isUserFullyAuthenticated()) {
      retVal = AppConstants.PATH_PROFILE;
    } else {
      retVal = AppConstants.PATH_LOGIN;
    }
    return retVal;
  }

  isUserAuthenticated(): boolean {
    return this.isUserPartiallyAuthenticated() || this.isUserFullyAuthenticated();
  }

  private isUserPartiallyAuthenticated(): boolean {
    return this.appStateService.getAppState().getAuthenticationState() &&
      this.appStateService.getAppState().getAuthenticationState().hasSession()
    ;
  }

  private isUserFullyAuthenticated(): boolean {
    return this.appStateService.getAppState().getAuthenticationState() &&
      this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()
    ;
  }

  public isInitialized(): boolean {
    return this.confReady;
  }

  showUI(): boolean {
    return this.appStateReady && this.isInitialized();
  }

  public disableAnimations(): boolean {
    // for disabling our java client lib anims append: |javafx\/
    const retVal: boolean = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    return retVal;
  }
}
