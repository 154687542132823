import {AuthenticationFlows} from '../gen/model/authenticationFlows';
import {AuthenticationChallenge, AuthenticationFlow} from '../gen';
import {AuthenticationChallenges} from '../gen/model/authenticationChallenges';
import {AppConstants} from '../app/AppConstants';
const cloneDeep = require('lodash.clonedeep');

export default class AuthenticationUtils {
  public static findNextChallenge(
    authenticationFlows: AuthenticationFlows,
    existingAuthentications: Array<AuthenticationChallenge>,
    userDefinedAcceptInvitationChallenge?: AuthenticationChallenge): AuthenticationChallenge {
    let retVal: AuthenticationChallenge = null;
    const branchedFlows: AuthenticationFlows = AuthenticationUtils.splitAvailableFlowsToBranches(authenticationFlows);
    const currentFlow: AuthenticationFlow = AuthenticationUtils.findMostCompletedBranchedFlow(
      branchedFlows, existingAuthentications);
    let cChallenge: AuthenticationChallenge = currentFlow.challenges[0];
    let nextChallenge = null;
    while (cChallenge) {
      if (existingAuthentications.filter(
          (val) => val.authenticationMethod === cChallenge.authenticationMethod).length) {
        cChallenge = cChallenge.challenges ? cChallenge.challenges[0] : null;
      } else {
        nextChallenge = cChallenge;
        break;
      }
    }
    if (userDefinedAcceptInvitationChallenge && nextChallenge.authenticationMethod === AppConstants.AC_AM_INVITATION) {
      retVal = userDefinedAcceptInvitationChallenge;
    } else {
      retVal = nextChallenge;
    }
    if (!retVal && userDefinedAcceptInvitationChallenge) {
      retVal = userDefinedAcceptInvitationChallenge;
    }
    return retVal;
  }
  public static findMostCompletedBranchedFlow(
    branchedFlows: AuthenticationFlows,
    existingAuthentications: AuthenticationChallenges): AuthenticationFlow {
    let retVal: AuthenticationFlow;
    const completedStepsInBranchedFlows = [];
    for (let i = 0; i < branchedFlows.length; i += 1) {
      const flow = branchedFlows[i];
      let challenge = flow.challenges[0];
      completedStepsInBranchedFlows[i] = { key: flow.key, completed: 0 };
      while (challenge) {
        if (existingAuthentications.filter(
            (val) => val.authenticationMethod === challenge.authenticationMethod).length) {
          completedStepsInBranchedFlows[i].completed += 1;
          challenge = challenge.challenges ? challenge.challenges[0] : null;
        } else {
          break;
        }
      }
    }
    completedStepsInBranchedFlows.sort((a, b) => b.completed - a.completed);
    retVal = branchedFlows.filter(val => val.key === completedStepsInBranchedFlows[0].key)[0];
    return retVal;
  }
  public static splitAvailableFlowsToBranches(flows: AuthenticationFlows): AuthenticationFlows {
    const retVal: AuthenticationFlows = [] as AuthenticationFlows;
    if (flows) {
      for (let i = 0; i < flows.length; i += 1) {
        for (let j = 0; j < flows[i].challenges.length; j += 1) {
          const branches: AuthenticationChallenge[] = [] as AuthenticationChallenge[];
          AuthenticationUtils.splitToBranches([flows[i].challenges[j]], branches);
          for (let k = 0; k < branches.length; k += 1) {
            const tf = cloneDeep(flows[i]);
            tf.challenges = [branches[k]];
            retVal.push(tf);
          }
        }
      }
    }
    return retVal;
  }
  private static splitToBranches(parentChallengeChain: AuthenticationChallenge[],
                                 branches: AuthenticationChallenge[]) {
    const nextChallenges: AuthenticationChallenge[] = parentChallengeChain[parentChallengeChain.length - 1].challenges;
    if (!nextChallenges || nextChallenges.length === 0) {
      const branchChallenges: AuthenticationChallenge[] = [].concat(parentChallengeChain);
      const branch: AuthenticationChallenge = AuthenticationUtils.compileBranch(branchChallenges);
      branches.push(branch);
    } else {
      for (let i = 0; i < nextChallenges.length; i += 1) {
        const nextChallenge: AuthenticationChallenge = nextChallenges[i];
        parentChallengeChain.push(nextChallenge);
        AuthenticationUtils.splitToBranches(parentChallengeChain, branches);
        parentChallengeChain.pop();
      }
    }
  }
  private static compileBranch(branchChallenges: AuthenticationChallenge[]): AuthenticationChallenge {
    const retValue: AuthenticationChallenge = cloneDeep(branchChallenges.shift());
    if (branchChallenges.length) {
      retValue.challenges = [AuthenticationUtils.compileBranch(branchChallenges)];
    }
    return retValue;
  }
}
