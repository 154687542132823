/**
 * 10Duke Session API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddAuthenticationsResult } from '../model/addAuthenticationsResult';
import { ApiError } from '../model/apiError';
import { User } from '../model/user';
import { UserForRegisterUser } from '../model/userForRegisterUser';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserService {

    protected basePath = 'http://localhost:8080/api/0.exp';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes the user account
     * Deletes user account of the authenticated user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets user details
     * Gets details of the authenticated user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<User>(`${this.basePath}/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Registers a new user account
     * Registers a new user account. For successful registration, user name and password authentication is established.
     * @param body Describes the user to register
     * @param verifyEmail Indicates if email verification process is started by the registration. If set to \&quot;true\&quot;, a message is sent to the registered email address, and user can verify the email address by following instructions given in the message.
     * @param verificationUri URI where user can navigate for completing email verification. Value of this parameter must contain format argument holder {0} for the email verification code.
     * @param remember Indicates if authenticated session created by the operation should be created as a persistent session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUser(body: UserForRegisterUser, verifyEmail?: boolean, verificationUri?: string, remember?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AddAuthenticationsResult>;
    public registerUser(body: UserForRegisterUser, verifyEmail?: boolean, verificationUri?: string, remember?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddAuthenticationsResult>>;
    public registerUser(body: UserForRegisterUser, verifyEmail?: boolean, verificationUri?: string, remember?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddAuthenticationsResult>>;
    public registerUser(body: UserForRegisterUser, verifyEmail?: boolean, verificationUri?: string, remember?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerUser.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (verifyEmail !== undefined && verifyEmail !== null) {
            queryParameters = queryParameters.set('verifyEmail', <any>verifyEmail);
        }
        if (verificationUri !== undefined && verificationUri !== null) {
            queryParameters = queryParameters.set('verificationUri', <any>verificationUri);
        }
        if (remember !== undefined && remember !== null) {
            queryParameters = queryParameters.set('remember', <any>remember);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AddAuthenticationsResult>(`${this.basePath}/user`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates user details
     * Updates details of the authenticated user
     * @param body Describes the updated user details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(body: User, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateUser(body: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateUser(body: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateUser(body: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/user`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
