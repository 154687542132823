import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailVerificationService } from '../../gen/api/emailVerification.service';
import { VerifyLoginEmailCredential } from '../../gen/model/verifyLoginEmailCredential';
import { Logger } from '../../util/logger';
import { AppConstants } from '../AppConstants';
import { LoginBaseComponent } from '../login-base.component';
import { ParamsService } from '../params.service';
import { AlertHandler } from '../no-concurrent-alerts-handler';
import { cardFooterFeedbackTransition } from '../animations';
import {animate, keyframes, query, style, transition, trigger} from '@angular/animations';
import LocationUtil, {UrlParams, getAppBaseHref} from '../../util/locationUtil';
import {Observable, timer} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../configuration-service';
import {AppStateService, ParamsForRouteNav} from '../app-state.service';


@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition,
    trigger('sendValidationFeedbackTransition', [
      transition(
        'void => *',
        [
          query(':self', [
            animate('200ms ease-in',
              keyframes([
                style({ transform: 'scaleY(0)' }),
                style({ transform: 'scaleY(1)' }),
              ])
            )
          ])
        ]
      ),
      transition(
        '* => void',
        [
          query(':self', [
            animate('200ms ease-in',
              keyframes([
                style({ transform: 'scaleY(1)' }),
                style({ transform: 'scaleY(0)' }),
              ])
            )
          ])
        ]
      ),
    ]),
  ],
})
export class ValidateEmailComponent extends LoginBaseComponent implements OnInit, OnDestroy {
  private static INITIAL_SEND_TIMEOUT = 2;
  private static RESEND_TIMEOUT = 5;
  validationForm = new FormGroup({
    key: new FormControl('', [Validators.required]),
  });

  sendEmailForm = new FormGroup({
    email: new FormControl('', [Validators.email]),
  });

  key: string;
  email: string;
  emailsent: boolean;
  emailfailed: boolean;
  genericError: boolean;
  validationFailed: boolean;
  validationCodeExpired: boolean;
  showCodeInput: boolean;
  private tooManyAttemptsError: boolean;
  private tooManyAttemptsTimeoutCounter: any;
  private tooManyAttemptsTimeout: number;
  sendEmailTimeout: number;
  private sendEmailTimeoutCounter: any;

  constructor(
    route: ActivatedRoute,
    router: Router,
    private appStateService: AppStateService,
    @Inject('EmailVerificationApi') private emailVerificationApi: EmailVerificationService,
    private paramsService: ParamsService,
    private alertHandler: AlertHandler,
    private logger: Logger,
    private translate: TranslateService,
    hostElement: ElementRef,
    configuration: ConfigurationService) {
    super(route, router, hostElement, configuration);
    this.showCodeInput = this.configuration.getProperties().showEmailValidationCodeInput;
  }
  ngOnInit() {
    super.init();
    this.emailsent = false;
    this.emailfailed = false;
    this.validationFailed = false;
    this.genericError = false;
    this.validationCodeExpired = false;
    this.subscribeParamsState();
    this.sendEmailTimeout = ValidateEmailComponent.INITIAL_SEND_TIMEOUT;
    this.sendEmailTimeoutCounter = timer(1000, 1000).subscribe(() => {
      this.sendEmailTimeout--;
      if (this.sendEmailTimeout <= 0) {
        this.sendEmailTimeout = undefined;
        this.sendEmailTimeoutCounter.unsubscribe();
        this.sendEmailTimeoutCounter = undefined;
      }
    });
  }
  ngOnDestroy() {
    if (this.sendEmailTimeoutCounter) {
      this.sendEmailTimeout = undefined;
      this.sendEmailTimeoutCounter.unsubscribe();
      this.sendEmailTimeoutCounter = undefined;
    }
  }

  private subscribeParamsState() {
    const keyPromise = this.paramsService.getParam(AppConstants.QP_EMAIL_VALIDATION_KEY);
    const emailPromise = this.paramsService.getParam(AppConstants.QP_EMAIL);
    Promise.all([keyPromise, emailPromise]).then((values) => {
      const keyUrlParams = values[0];
      let key;
      if (keyUrlParams) {
        if (Array.isArray(keyUrlParams)) {
          key = keyUrlParams[0];
        } else {
          key = keyUrlParams;
        }
      }
      this.key = key;
      if (this.key) {
        this.validationForm.get('key').setValue(this.key);
      }
      const keyEmailParams = values[1];
      let email;
      if (keyEmailParams) {
        if (Array.isArray(keyEmailParams)) {
          email = keyEmailParams[0];
        } else {
          email = keyEmailParams;
        }
      }
      this.email = email;
      if (this.email) {
        this.sendEmailForm.get('email').setValue(this.email);
      }
      if (this.key && this.appStateService.getAppState().getAuthenticationState().hasSession()) {
        this.logger.debug('auto submitting valid form');
        this.submit();
      }
    });
  }

  submit() {
    const k = this.validationForm.get('key').value;
    this.validationCodeExpired = false;
    this.validationFailed = false;
    this.genericError = false;
    this.logger.debug('Submitted: %s', k);
    const verification: VerifyLoginEmailCredential = {
      authenticationMethod: AppConstants.AC_AM_VERIFY_EMAIL,
      verificationCode: k
    };
    this.appStateService.addAuthentications([verification], false )
      .subscribe(response => {
        this.tooManyAttemptsError = undefined;
        this.tooManyAttemptsTimeout = undefined;
        if (this.tooManyAttemptsTimeoutCounter) {
          this.tooManyAttemptsTimeoutCounter.unsubscribe();
        }
        this.tooManyAttemptsTimeoutCounter = undefined;

        const t: ParamsForRouteNav|string = this.appStateService.getAppState().resolveNextRoute([
          AppConstants.QP_EMAIL_VALIDATION_KEY,
          AppConstants.QP_EMAIL,
        ]);
        this.logger.debug('Validating email completed: %o', t);
        if (typeof t === 'string') {
          window.location.href = t as string;
        } else if (t) {
          this.router.navigate([t.path], {
            queryParams: t.queryParams,
            fragment: t.fragment,
          });
        }
      }, response => {
        this.logger.debug('Validating email failed, response %O', response);
        this.tooManyAttemptsError = 'authentication_attempts_restricted' === response.error.error;
        if (response.error.waitSeconds > 2) {
          // better to not react if less than 2 seconds, as there is not enough time for the user to read the info
          this.tooManyAttemptsTimeout = response.error.waitSeconds;
          this.tooManyAttemptsTimeoutCounter =  timer(1000, 1000).subscribe(() => {
            this.tooManyAttemptsTimeout--;
            if (this.tooManyAttemptsTimeout <= 0) {
              this.tooManyAttemptsTimeout = undefined;
              this.tooManyAttemptsTimeoutCounter.unsubscribe();
              this.tooManyAttemptsTimeoutCounter = undefined;
            }
          });
        }
        if (response && response.error === 'credentials_expired') {
          this.validationCodeExpired = true;
        }
        this.validationFailed = true;
        this.genericError = true;
      })
    ;
  }

  sendEmail() {
    this.emailfailed = false;
    this.emailsent = false;
    const verificationUri = [];
    verificationUri.push(LocationUtil.getOwnDomainURL());
    verificationUri.push(getAppBaseHref());
    verificationUri.push(AppConstants.ROUTE_VALIDATE_EMAIL);
    if (verificationUri[0].indexOf('?') < 0) {
      verificationUri.push('?');
    } else {
      verificationUri.push('&');
    }
    verificationUri.push(AppConstants.QP_EMAIL_VALIDATION_KEY);
    verificationUri.push('={0}');
    verificationUri.push(
      LocationUtil.validParamsToQueryString(
        '&',
        [AppConstants.QP_EMAIL_VALIDATION_KEY],
          this.configuration.getProperties().allowedOriginsForNextParam)
    );
    this.emailVerificationApi.sendEmailVerification({ verificationUri: verificationUri.join('') }, 'response')
      .subscribe(response => {
        this.logger.debug('Sending email completed, response %d', response.status);
        this.emailsent = true;
        this.sendEmailTimeout = ValidateEmailComponent.RESEND_TIMEOUT;
        this.sendEmailTimeoutCounter = timer(1000, 1000).subscribe(() => {
          this.sendEmailTimeout--;
          if (this.sendEmailTimeout <= 0) {
            this.sendEmailTimeout = undefined;
            this.sendEmailTimeoutCounter.unsubscribe();
            this.sendEmailTimeoutCounter = undefined;
          }
        });
      }, response => {
        this.logger.debug('Sending email  failed, response %O', response);
        this.emailfailed = true;
      })
    ;
  }

  cancel() {
    if (this.appStateService.getAppState().getAuthenticationState().hasSession() &&
      this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()) {
      this.router.navigate([AppConstants.PATH_PROFILE], {
        relativeTo: this.route,
      });
    } else if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      this.router.navigate([AppConstants.PATH_LOGOUT], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([AppConstants.PATH_HOME], {
        relativeTo: this.route,
      });
    }
  }

  showSendEmailForm(): boolean {
    return !this.key || this.validationFailed;
  }

  hasError(field?: AbstractControl, code?: string): boolean {
    if (field && field === this.validationForm.get('key') && this.validationFailed && this.validationCodeExpired) {
      return true;
    } else {
      return this.genericError;
    }
  }

  public hasTooManyAttemptsTimeout(): boolean {
    return this.tooManyAttemptsTimeout > 0;
  }
  isFieldInvalid(field: AbstractControl): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  // noinspection JSMethodCanBeStatic
  isInputInvalid(field: AbstractControl): boolean {
    return field.invalid && field.touched;
  }

  isFieldValid(field: AbstractControl): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }

  // noinspection JSMethodCanBeStatic
  isInputValid(field: AbstractControl): boolean {
    return field.valid && field.touched;
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('validate-email.window-title');
  }

}
