import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {BASE_PATH, InvitationService, TotpService} from '../../gen';
import { ApiModule } from '../../gen/api.module';
import { AuthenticationService } from '../../gen/api/authentication.service';
import { AuthenticationFlowService } from '../../gen/api/authenticationFlow.service';
import { EmailVerificationService } from '../../gen/api/emailVerification.service';
import { PasswordService } from '../../gen/api/password.service';
import { SessionService } from '../../gen/api/session.service';
import { UserService } from '../../gen/api/user.service';
import { Configuration } from '../../gen/configuration';
import { getApiBasePath } from './api-base-path';
import {fetchApiDescription} from './api-description';

const withDefaultHeaders = (service: any) => {
  service.defaultHeaders = service.defaultHeaders.append("X-10Duke-Client", "sso-ui");
  return service;
};

const authenticationServiceProvider = (httpClient: HttpClient, configuration: Configuration) =>
  withDefaultHeaders(new AuthenticationService(httpClient, getApiBasePath(), configuration));
const authenticationFlowServiceProvider = (httpClient: HttpClient, configuration: Configuration) =>
  withDefaultHeaders(new AuthenticationFlowService(httpClient, getApiBasePath(), configuration));
const sessionApiServiceProvider = (httpClient: HttpClient, configuration: Configuration) =>
  withDefaultHeaders(new SessionService(httpClient, getApiBasePath(), configuration));
const userApiServiceProvider = (httpClient: HttpClient, configuration: Configuration) =>
  withDefaultHeaders(new UserService(httpClient, getApiBasePath(), configuration));
const emailVerificationApiServiceProvider = (httpClient: HttpClient, configuration: Configuration) =>
  withDefaultHeaders(new EmailVerificationService(httpClient, getApiBasePath(), configuration));
const passwordApiServiceProvider = (httpClient: HttpClient, configuration: Configuration) =>
  withDefaultHeaders(new PasswordService(httpClient, getApiBasePath(), configuration));
const invitationApiServiceProvider = (httpClient: HttpClient, configuration: Configuration) =>
  withDefaultHeaders(new InvitationService(httpClient, getApiBasePath(), configuration));
const totpApiServiceProvider = (httpClient: HttpClient, configuration: Configuration) =>
  withDefaultHeaders(new TotpService(httpClient, getApiBasePath(), configuration));

@NgModule({
  providers: [
    { provide: 'AuthenticationApi', useFactory: authenticationServiceProvider, deps: [HttpClient, Configuration] },
    { provide: 'AuthenticationFlowApi', useFactory: authenticationFlowServiceProvider, deps: [HttpClient, Configuration] },
    { provide: 'SessionApi', useFactory: sessionApiServiceProvider, deps: [HttpClient, Configuration] },
    { provide: 'UserApi', useFactory: userApiServiceProvider, deps: [HttpClient, Configuration] },
    { provide: 'EmailVerificationApi', useFactory: emailVerificationApiServiceProvider, deps: [HttpClient, Configuration] },
    { provide: 'PasswordApi', useFactory: passwordApiServiceProvider, deps: [HttpClient, Configuration] },
    { provide: 'InvitationApi', useFactory: invitationApiServiceProvider, deps: [HttpClient, Configuration] },
    { provide: 'TotpApi', useFactory: totpApiServiceProvider, deps: [HttpClient, Configuration] },
    { provide: BASE_PATH, useFactory: getApiBasePath },
    { provide: 'FetchOpenAPIDescription', useFactory: fetchApiDescription}
  ]
})
export class RealApiModule extends ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<RealApiModule> {
    return {
      ngModule: RealApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient) {
    super(parentModule, http);
  }
}
