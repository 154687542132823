/**
 * 10Duke Session API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Describes a consumer (OAuth 2.0 client or SAML Service Provider) or a provider (OpenID Connect or SAML Identity Provider).
 */
export interface SsoParticipant { 
    /**
     * Id of the external application, as used for identifying the application and related configuration in this system
     */
    applicationId: string;
    /**
     * Name of the external application
     */
    applicationName?: string;
    /**
     * Name of protocol to use for communicating with the external application. Supported values are \"SAML 2.0\" for Security Assertion Markup Language 2.0 and \"OIDC 1.0\" for OpenID Connect 1.0.
     */
    protocol?: SsoParticipant.ProtocolEnum;
}
export namespace SsoParticipant {
    export type ProtocolEnum = 'OIDC 1.0' | 'SAML 2.0';
    export const ProtocolEnum = {
        OIDC10: 'OIDC 1.0' as ProtocolEnum,
        SAML20: 'SAML 2.0' as ProtocolEnum
    };
}