import {
  trigger,
  animate,
  transition,
  style,
  query, group, useAnimation, animation, keyframes, sequence} from '@angular/animations';

const opt = { optional: true };

/*
* Angular animation support is very very limited and quirky. Only items from this list are supported:
* https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_animated_properties
* The offsets do not seem to work logically, and as there is no way to "clear" animation states after animation, a 1ms animation
* clearing the states has been used.
 */
const routeFlipAnimation: any = animation([sequence([
  // Ensures that no scrollbars are introduce while animating and prepares the container for the anim.
  query(
    ':self',
    [
      style({
        'max-height': '100vh',
        overflow: 'hidden',
        position: 'relative',
        perspective: '1000px',
      })
    ],
    opt
  ),
  // prepare the entering view and hide it initially
  query(
    ':enter',
    [
      style({
        'z-index': 2,
        'max-height': '0px',
        overflow: 'hidden',
      })
    ],
    opt
  ),
  // prepare the leaving view
  query(
    ':leave',
    [
      style({
        'z-index': 2,
        'max-height': 'unset',
        transform: 'rotateY(0deg)',
        overflow: 'hidden',
      })
    ],
    opt
  ),
  // rotate out the leaving view
  query(
    ':leave',
    [animate('{{duration}}ms 0s ease-in',
      style({
        transform: 'rotateY({{leave}}deg)',
      }),
    )],
    opt
  ),
  // hide the leaving view, has to be separate and "animated" as angular animations will mess it up otherwise
  query(
    ':leave',
    [animate('1ms 0s linear',
      style({
        transform: 'none',
        'max-height': '0px',
      })),
    ],
    opt
  ),
  // rotate in the entering view
  query(
    ':enter',
    [animate('{{duration}}ms 0s ease-out', keyframes([
      style({
        transform: 'rotateY({{enter}}deg)',
        'max-height': 'unset',
      }),
      style({
        transform: 'rotateY(0deg)',
        'max-height': 'unset',
      }),
    ]))],
    opt
  ),
])]);


const flip_right = { enter: 90, leave: -90, duration: 200 };
const flip_left = { enter: -90, leave: 90, duration: 200 };

// cubic-bezier(0.485, 0.155, 0.24, 1.245) !important
export function createZoomInAnimation(selector: string, options?: any): any {
  return animation([
    query(
      selector,
      [
        animate(
          '{{duration}}ms 0s cubic-bezier(.75,.32,.19,1.4)',
          keyframes([
            style({
              transform: 'scale3d(0, 0, 0)',
              opacity: 0,
              offset: 0
            }),
            style({
              transform: 'scale3d(1, 1, 1)',
              opacity: 1,
              offset: 1
            })
          ])
        ),
      ],
      options || { params: { duration: 600 } }
    ),
  ]);
}

const routeInitTransition: any = animation([
  // hide the footer as it's not possible to keep it in place during animations.
  query('footer', [style({ opacity: 0 })], opt),
  query(
    ':self',
    [
      style({
        position: 'relative',
        perspective: '1000px',
      })
    ],
    opt
  ),
  // hide the incomin view and prepare it for anim
  query(
    ':enter:not(header):not(footer):not(app-duke-alert):not(.nextNotification)',
    [
      style({
        'transform-origin': '0 0',
        display: 'block',
        overflow: 'visible',
        'z-index': 2,
      }),
    ],
    opt
  ),
  // ensure that no scrollbars are introduced with the zoomin animation
  query(
    'header',
    [style({ overflow: 'hidden' })],
    opt
  ),
  group([
    useAnimation(createZoomInAnimation('header h1', { params: { duration: 600 }, optional: true })),
    // Animate the incoming view
    query(
      ':enter:not(header):not(footer):not(app-duke-alert):not(app-duke-alert):not(.nextNotification)',
      [
        animate(
          '600ms 0ms cubic-bezier(0.2, 0, 0.265, 1.550)',
          keyframes([
            style({
              transform: 'rotateX(-90deg)',
              offset: 0,
            }),
            style({
              transform: 'rotateX(0deg)',
              offset: 1,
            })
          ])
        ),
      ],
      opt
    ),
    query(
      'footer',
      [
        animate(
          '200ms 400ms ease-out',
          keyframes([
            style({
              opacity: 0,
              'z-index': 0,
              transform: 'translateY(-100%)'
            }),
            style({
              opacity: 1,
              'z-index': 0,
              transform: 'translateY(0%)'
            }),
          ])
        )
      ],
      opt
    ),
  ]),
]);

export const routeTransition = trigger('routeAnimations', [
  // The '* => *' will trigger the animation to change between any two states
  // disable all animations for Home component, which is simply an empty placeholder for default/root route,
  // shown briefly before app is properly initialized
  transition(
    'void => Home',
    []
  ),
  transition(
    'Home => *',
    useAnimation(routeInitTransition)
  ),
  transition(
    'APP_INIT => *',
    useAnimation(routeInitTransition)
  ),
  transition(
    'void => *',
    useAnimation(routeInitTransition)
  ),
  // nasty hack to prevent this setup playing animations on IE and Edge, as suprise suprise,
  // they fail and angular makes adding a fix next to impossible. Turns out there were so many error with angular on IE,
  // that IE support has been disabled for now. Leaving this here in case angular improves and we can enable support in the future.
  transition(
    'void => APP_INIT', []
  ),
  transition(
    '* => Login',
    useAnimation(routeFlipAnimation, { params: flip_left })
  ),
  transition(
    '* => Logout',
    useAnimation(routeFlipAnimation, { params: flip_left })
  ),
  transition(
    'EditProfile => ViewProfile',
    useAnimation(routeFlipAnimation, { params: flip_left })
  ),
  transition(
    'ChangePassword => *',
    useAnimation(routeFlipAnimation, { params: flip_left })
  ),
  transition(
    'ConfigureTotp => *',
    useAnimation(routeFlipAnimation, { params: flip_left })
  ),
  transition(
    '* => *',
    useAnimation(routeFlipAnimation, { params: flip_right })
  ),
]);
export const cardFooterFeedbackTransition = trigger('cardFooterFeedbackTransition', [
  transition(
    'void => *',
    [
      query(':self', [
        animate('200ms ease-in-out',
          keyframes([
            style({ 'transform-origin': '100% 50%', transform: 'scaleX(0)' }),
            style({ 'transform-origin': '100% 50%', transform: 'scaleX(1)' }),
          ])
        )
      ])
    ]
  ),
  transition(
    '* => void',
    [
      query(':self', [
        animate('200ms ease-in-out',
          keyframes([
            style({ 'transform-origin': '100% 50%', transform: 'scaleX(1)' }),
            style({ 'transform-origin': '100% 50%', transform: 'scaleX(0)' }),
          ])
        )
      ])
    ]
  ),
]);
export const inputFeedbackTransition = trigger('inputFeedbackTransition', [
  transition(
    'void => *',
    [
      query(':self', [
        animate('200ms ease-in-out',
          keyframes([
            style({ 'transform-origin': '100% 0%', transform: 'scale(0)' }),
            style({ 'transform-origin': '100% 0%', transform: 'scale(1)' }),
          ])
        )
      ])
    ]
  ),
  transition(
    '* => void',
    [
      query(':self', [
        animate('200ms ease-in-out',
          keyframes([
            style({ 'transform-origin': '100% 0%', transform: 'scale(1)' }),
            style({ 'transform-origin': '100% 0%', transform: 'scale(0)' }),
          ])
        )
      ])
    ]
  ),
]);
export const alertTransition = trigger('alertTransition', [
  transition(
    'void => *',
    [
      group([
        // Animate in the alert
        query('.alert-danger:not(.static), .alert-success:not(.static), .alert.floating', [
          style({
            'transform-origin': '50% 50%',
            position: 'fixed',
            top: '0',
            left: '50%',
          }),
          animate('400ms cubic-bezier(0.230, 1.000, 0.320, 1.150)', keyframes([
            style({
              offset: 0,
              transform: 'translateY(-200%) translateX(-50%) scaleY(2.5) scaleX(0.2)',
              filter: 'blur(40px)',
              opacity: 0,
            }),
            style({
              offset: 1,
              transform: 'translateY(0%) translateX(-50%) scaleY(1) scaleX(1)',
              top: '1rem',
              filter: 'blur(0px)',
              opacity: 1,
            }),
          ])),
        ], opt),
      ]),
      // add error state animation
      query('.alert-danger:not(.static)', [
        animate('400ms 200ms cubic-bezier(0.455, 0.030, 0.515, 0.955)', keyframes([
          style({
            offset: 0,
            transform: 'translateX(-50%)',
          }),
          style({
            offset: 0.1,
            transform: 'translateX(calc(-50% - 10px))',
          }),
          style({
            offset: 0.2,
            transform: 'translateX(calc(-50% + 10px))',
          }),
          style({
            offset: 0.3,
            transform: 'translateX(calc(-50% - 10px))',
          }),
          style({
            offset: 0.4,
            transform: 'translateX(calc(-50% + 10px))',
          }),
          style({
            offset: 0.5,
            transform: 'translateX(calc(-50% - 10px))',
          }),
          style({
            offset: 0.6,
            transform: 'translateX(calc(-50% + 10px))',
          }),
          style({
            offset: 0.7,
            transform: 'translateX(calc(-50% -10px))',
          }),
          style({
            offset: 0.8,
            transform: 'translateX(calc(-50% + 8px))',
          }),
          style({
            offset: 0.9,
            transform: 'translateX(calc(-50% - 8px))',
          }),
          style({
            offset: 1,
            transform: 'translateX(-50%)',
          }),
        ]))
      ], opt),
    ]
    ),
  transition(
    '* => void',
    [
      // prepare container and animate the height change with max height hack
      query('.alert-danger:not(.static), .alert-success:not(.static), .alert.floating', [
        style({
          'transform-origin': '50% 50%',
          top: '1rem',
          'z-index': 0,
        }),
        animate('300ms cubic-bezier(0.550, 0.085, 0.680, 0.530)', keyframes([
          style({
            offset: 0,
            opacity: 1,
            transform: 'translateX(-50%) translateY(0vh) scale(1)',
            'box-shadow': '0 0.5rem 1rem rgba(0,0,0,0.15)',
          }),
          style({
            offset: 0.2,
            opacity: 0.92,
            transform: 'translateX(-50%) translateY(20vh) scale(0.92)',
            'box-shadow': '0 0rem 0rem rgba(0,0,0,0.15)',
          }),
          style({
            offset: 1,
            opacity: 0.1,
            transform: 'translateX(-50%) translateY(100vh) scale(0.1)',
          }),
        ])),
      ], opt),
  ]),
]);
export function createTabSlideTransitionSteps(oldTab: any, newTab: any, dur?: number) {
  const oldTabHeight = oldTab.nativeElement.offsetHeight;
  const newTabHeight = newTab.nativeElement.scrollHeight;
  const duration = dur || 300;
  return [
    group([
      // animater the holder elements height change
      query(':self', [
        style({height: oldTabHeight + 'px'}),
        animate(duration + 'ms 0ms ease-in-out', style({height: newTabHeight + 'px'})),
        style({height: 'unset'}),
      ]),
      sequence([
        query('#' + newTab.nativeElement.id, [
          style({
            position: 'relative',
            'z-index': 1,
            'transform-origin': '0 0',
            top: '-' + newTabHeight + 'px',
            'max-height': 'unset'
          }),
        ]),
        query('#' + oldTab.nativeElement.id, [
          style({position: 'absolute', 'z-index': 0, width: '100%', top: 0, 'transform-origin': '0 0'}),
          animate((duration / 2) + 'ms 0ms ease-in-out', style({top: '-100%'})),
        ]),
        query('#' + newTab.nativeElement.id, [
          animate((duration / 2) + 'ms 0ms ease-in-out', style({top: 0})),
          style({position: 'static', 'z-index': 0}),
        ]),
      ]),
    ])
  ];
}
export function createTabFadeTransitionSteps(oldTab: any, newTab: any, dur?: number) {
  const oldTabHeight = oldTab.nativeElement.offsetHeight;
  const newTabHeight = newTab.nativeElement.scrollHeight;
  const duration = dur || 300;
  return [
    group([
      // animater the holder elements height change
      query(':self', [
        style({height: oldTabHeight + 'px'}),
        animate(duration + 'ms 0ms ease-in-out', style({height: newTabHeight + 'px'})),
        style({height: 'unset'}),
      ]),
      sequence([
        query('#' + newTab.nativeElement.id, [
          style({
            position: 'relative',
            'z-index': 1,
            'max-height': 'unset',
            opacity: 0,
          }),
        ]),
        query('#' + oldTab.nativeElement.id, [
          style({
            position: 'absolute'
          }),
        ]),
        query('#' + newTab.nativeElement.id, [
          animate((duration) + 'ms 0ms ease-in-out', style({opacity: 1})),
          style({position: 'static', 'z-index': 0}),
        ]),
      ]),
    ])
  ];
}
export function createTabSlideUpAndFadeInTransitionSteps(oldTab: any, newTab: any, dur?: number) {
  const oldTabHeight = oldTab.nativeElement.offsetHeight;
  const newTabHeight = newTab.nativeElement.scrollHeight;
  const oldBodyHeight = oldTab.nativeElement.querySelector('.card-body').offsetHeight;
  const duration = dur || 300;
  return [
    group([
      // animater the holder elements height change
      query(':self', [
        style({height: oldTabHeight + 'px'}),
        animate(duration + 'ms 0ms ease-in-out', style({height: newTabHeight + 'px'})),
        style({height: 'unset'}),
      ]),
      query('#' + newTab.nativeElement.id, [
        style({
          position: 'static',
        })
      ]),
      query('#' + oldTab.nativeElement.id, [
        style({
          position: 'absolute',
          top: 0,
          width: 'calc(100% - 10px)',
          'z-index': 1,
        })
      ]),
      query('#' + oldTab.nativeElement.id + ' .card-body', [
        style({
          position: 'relative',
          top: 0,
        }),
        animate((duration) + 'ms 0ms ease-in-out', style({top: '-' + oldBodyHeight + 'px'})),
      ]),
      query('#' + oldTab.nativeElement.id + ' .card-footer', [
        style({
          opacity: 0,
        })
      ]),
    ]),
    query('#' + oldTab.nativeElement.id, [
      style({
        position: 'absolute',
        top: '-100%',
      })
    ]),
    query('#' + oldTab.nativeElement.id + ' .card-footer', [
      style({
        opacity: 1,
      })
    ]),
  ];
}
export function createTabSlideDownAndFadeInTransitionSteps(oldTab: any, newTab: any, dur?: number) {
  const oldTabHeight = oldTab.nativeElement.offsetHeight;
  const newTabHeight = newTab.nativeElement.scrollHeight;
  const newBodyHeight = newTab.nativeElement.querySelector('.card-body').offsetHeight;
  const duration = dur || 300;
  return [
    group([
      // animater the holder elements height change
      query(':self', [
        style({height: oldTabHeight + 'px'}),
        animate(duration + 'ms 0ms ease-in-out', style({height: newTabHeight + 'px'})),
        style({height: 'unset'}),
      ]),
      query('#' + oldTab.nativeElement.id, [
        style({
          position: 'static',
        })
      ]),
      query('#' + newTab.nativeElement.id, [
        style({
          position: 'absolute',
          top: 0,
          width: 'calc(100% - 10px)',
          'z-index': 1,
        })
      ]),
      query('#' + newTab.nativeElement.id + ' .card-body', [
        style({
          position: 'relative',
          top: '-' + newBodyHeight + 'px',
        }),
        animate((duration) + 'ms 0ms ease-in-out', style({top: 0})),
      ]),
      query('#' + oldTab.nativeElement.id + ' .card-footer', [
        style({
          opacity: 0,
        })
      ]),
    ]),
    query('#' + oldTab.nativeElement.id, [
      style({
        position: 'absolute',
        top: '-100%',
      })
    ]),
    query('#' + oldTab.nativeElement.id + ' .card-footer', [
      style({
        opacity: 1,
      })
    ]),
    query('#' + newTab.nativeElement.id, [
      style({
        position: 'static',
      })
    ]),
  ];
}
