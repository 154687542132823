import {Component, ElementRef, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../gen/model/user';
import {AppConstants} from '../AppConstants';
import {StandaloneComponent} from '../standalone.component';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {cardFooterFeedbackTransition} from '../animations';
import {ActivateTotpConfigurationRequest, DeactivateTotpConfigurationRequest, TotpConfiguration, TotpService} from '../../gen';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../configuration-service';
import {AppStateService} from '../app-state.service';
const QRCode = require('qrcode');

@Component({
  selector: 'app-configure-totp',
  templateUrl: './configure-totp.component.html',
  styleUrls: ['./configure-totp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition
  ],
})
export class ConfigureTotpComponent extends StandaloneComponent implements OnInit {

  secretKey: string;
  qrCodeURL: string;
  qrCodeImage: string;
  totpIsActive: boolean;
  /**
   * Pattern for validating authentication code. Should match 6 digits, with arbitrary number of spaces
   */
  private codePattern: RegExp = /^([\s]*\d[\s]*){6}$/;

  activateTotpForm = new FormGroup({
    secretKey: new FormControl('', [Validators.required]),
    code1: new FormControl('', [Validators.required, Validators.pattern(this.codePattern)]),
    code2: new FormControl('', [Validators.required, Validators.pattern(this.codePattern)]),
  });

  invalidCode1 = false;
  invalidCode2 = false;
  invalidDeactivationCode = false;
  deactivateTotpForm = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.pattern(this.codePattern)]),
  });

  submitFailed = false;

  constructor(private appStateService: AppStateService,
              private alertHandler: AlertHandler,
              @Inject('TotpApi') private totpApi: TotpService,
              private translate: TranslateService,
              route: ActivatedRoute,
              router: Router,
              hostElement: ElementRef,
              configuration: ConfigurationService) {
    super(route, router, hostElement, configuration);
  }
  getProfile(): User {
    return this.appStateService.getAppState().getAuthenticationState().getProfile();
  }
  ngOnInit() {
    super.init();
    this.totpApi.getTotpConfiguration('response', false).subscribe(
      response => {
        if (response && response.body) {
          this.totpIsActive = response.body.activated;
          this.handleQRCodeURL(response.body.keyUri);
        }
      }, err => {
        this.totpIsActive = false;
        this.totpApi.createTotpConfiguration('response', false).subscribe(
          response2 => {
            if (response2 && response2.body) {
                const conf: TotpConfiguration = response2.body as TotpConfiguration;
                this.totpIsActive = conf.activated; // this must always be false???
                this.handleQRCodeURL(conf.keyUri);
            }
          }, err2 => {
            throw new Error('ConfigureTotpComponent.ngOnInit: creating conf failed');
          }
        );
      }
    );
  }
  handleQRCodeURL(url: string) {
    this.qrCodeURL = url;
    const t = url.split('secret=');
    this.handleSecretKey(t[t.length - 1].split('&')[0]);
    // Be sure to keep the width in line with styling
    QRCode.toDataURL(this.qrCodeURL, {
      errorCorrectionLevel: 'M',
      margin: 1,
      width: 240,
      color: {
        dark: '#000000',
        light: '#FFFFFF'
      }
    }).then((u) => {
      this.qrCodeImage = u;
    }).catch(err => {
      this.qrCodeImage = null;
      throw new Error('ConfigureTotpComponen.handleQRCodeURL: Generating qr code failed');
    });
  }
  handleSecretKey(key: string) {
    this.secretKey = key;
    this.activateTotpForm.get('secretKey').setValue(this.secretKey);
  }
  signOut() {
    this.router.navigate([AppConstants.PATH_LOGOUT], {
      relativeTo: this.route,
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  isInputInvalid(field: AbstractControl): boolean {
    /*
    if (field === this.activateTotpForm.get('confirmNewPassword')) {
      return (field.invalid ||
        (this.activateTotpForm.invalid && this.activateTotpForm.errors && this.activateTotpForm.errors.mustMatch)) && field.touched;

    } else {*/
      return field.invalid && field.touched;
    // }
  }

  isFieldValid(field: AbstractControl): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }

  isInputValid(field: AbstractControl): boolean {
    if (field === this.activateTotpForm.get('secretKey')) {
      return field.valid;
    } else {
      return field.valid && field.touched;
    }
  }

  hasError(field?: AbstractControl, code?: string): boolean {
    let retVal = false;
    if (!field) {
      retVal = this.submitFailed;
    } else if (field === this.activateTotpForm.get('code1')) {
      retVal = this.invalidCode1;
    } else if (field === this.activateTotpForm.get('code2')) {
      retVal = this.invalidCode2;
    } else if (field === this.deactivateTotpForm.get('code')) {
      retVal = this.invalidDeactivationCode;
    }
    return retVal;
  }

  submit() {
    this.submitFailed = false;
    if (this.totpIsActive) {
      this.invalidDeactivationCode = false;
      const code: DeactivateTotpConfigurationRequest = {
        code: this.deactivateTotpForm.get('code').value.replace(/\s/g, ''),
      };
      this.totpApi.deactivateTotpConfiguration(code, 'response', false).subscribe(
        response => {
          this.totpApi.deleteTotpConfiguration('response', false).subscribe(
            deleteResponse => {
              this.router.navigate([AppConstants.PATH_EDIT_PROFILE], {
                relativeTo: this.route,
                queryParamsHandling: 'merge',
                fragment: 'login',
              });
            }, (deleteErr) => {
              // no need to mark as submit failed, nothing gets broken even if delete fails
              // this.submitFailed = true;
              this.router.navigate([AppConstants.PATH_EDIT_PROFILE], {
                relativeTo: this.route,
                queryParamsHandling: 'merge',
                fragment: 'login',
              });
            }
          );
        }, (err) => {
          this.submitFailed = true;
          if (err && err.error && err.error.error === 'invalid_activation_code') {
            this.invalidDeactivationCode = true;
          }
        }
      );
    } else {
      this.invalidCode1 = false;
      this.invalidCode2 = false;
      const codes: ActivateTotpConfigurationRequest = {
        code1: this.activateTotpForm.get('code1').value.replace(/\s/g, ''),
        code2: this.activateTotpForm.get('code2').value.replace(/\s/g, ''),
      };
      this.totpApi.activateTotpConfiguration(codes, 'response', false).subscribe(
        (response) => {
          this.router.navigate([AppConstants.PATH_EDIT_PROFILE], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            fragment: 'login',
          });
        }, (err) => {
          this.submitFailed = true;
          this.invalidCode1 = true;
          this.invalidCode2 = true;
        }
      );
    }
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('configure-totp.window-title');
  }

}
